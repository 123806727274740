import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import {
  WorkOrderJiraDetail,
  WorkOrderMovementHistory
} from 'src/app/components/work-order-tracking/model';
import { JiraService } from 'src/app/services/jira.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { WorkOrderMovementReport } from '../work-order-moved-report';

@Component({
  selector: 'work-order-pd-movement',
  templateUrl: './pd-movement.component.html',
  styleUrls: ['./pd-movement.component.scss'],
})
export class PDMovementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  selectedItem: WorkOrderMovementReport = {} as WorkOrderMovementReport;
  displayedColumns = ['productName', 'jira_ID'];
  workOrderMovedItems = new MatTableDataSource<WorkOrderMovementReport>([]);
  workOrderMovementHistory: WorkOrderMovementHistory[] = [];
  jiraDetail: WorkOrderJiraDetail | null = null;
  poLabel = 'PO';
  poValue = '-';

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private jiraService: JiraService,
  ) {}

  ngOnInit(): void {
    this.getMovedWorkOrders();
  }

  ngAfterViewInit(): void {
    this.workOrderMovedItems.sort = this.sort;
  }

  getMovedWorkOrders() {
    this.workOrderMovementService
      .getPDMovementReportDateFilter()
      .subscribe((data) => {
        this.workOrderMovedItems.data = data;
        this.selectedItem = {} as WorkOrderMovementReport;
        this.jiraDetail = null;
        this.workOrderMovementHistory = [];
        this.poLabel = 'PO';
        this.poValue = '-';
      });
  }

  onSelectItem(item: WorkOrderMovementReport) {
    this.selectedItem = item;
    this.jiraDetail = null;
    this.onGetJiraDetails(item.jira_ID);
  }

  onGetJiraDetails(jiraId) {
    this.jiraService.scan(jiraId).subscribe((data = {}) => {
      this.jiraDetail = {
        jiraId: jiraId.toUpperCase(),
        name: data.summary ?? '[No Title]',
        picPath: data.defaultImage ?? 'assets/images/no-image.png',
        status: data.status ?? '[No Status]',
        description: data.description ?? { content: [] },
      };
      this.getPdWorkOrderMovement(jiraId);
    });
  }

  getPdWorkOrderMovement(jiraId) {
    this.workOrderMovementHistory = [];
    this.workOrderMovementService
      .getPdWorkOrderMovement(jiraId)
      .subscribe((data) => {
        this.workOrderMovementHistory = orderBy(
          data,
          'pdWorkMovementID',
          'asc',
        );
      });
  }
}
