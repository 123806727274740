<div class="riva-reporting">
  <div class="reporting-header">
    <div class="filters">
      <div>
        <mat-slide-toggle [(ngModel)]="isDateRange" style="color: white"
          >Show date range</mat-slide-toggle
        >
      </div>
      <div>
        <riva-date-range-picker
          [startDate]="filters.dateRangeFrom"
          [endDate]="filters.dateRangeTo"
          [isSinglePicker]="!isDateRange"
          (onChange)="onSetDateRange($event)"
        ></riva-date-range-picker>
      </div>
    </div>
    <div class="invoice-header">
      <div class="total-invoiced">
        <div class="label">Total Invoiced:</div>
        <div class="value">{{ totalInvoiced | currency }}</div>
      </div>
      <button
        class="btn btn-success"
        [disabled]="isGenerating"
        (click)="onGenerateReport()"
      >
        End of Day Report
      </button>
    </div>
  </div>
  <div class="invoice-table-container">
    <div class="material-section">
      <h2 style="margin-bottom: 8px">Metals</h2>
      <div class="table-container">
        <table
          mat-table
          matSort
          #materials
          #materialsSort="matSort"
          [dataSource]="materialList"
          matSortActive="description"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by description"
            >
              Metal
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalWeightShipped">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by totalWeightShipped"
            >
              Total Weight
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalWeightShipped }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="gems-row"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="2">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="stone-section">
      <h2 style="margin-bottom: 8px">Stones</h2>
      <div class="table-container">
        <table
          mat-table
          matSort
          #stones
          #stonesSort="matSort"
          [dataSource]="stoneList"
          matSortActive="stoneType"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="stoneType">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by stoneType"
            >
              Type
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneCut">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by stoneCut"
            >
              Cut
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneCut }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneClarity">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by stoneClarity"
            >
              Clarity
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneClarity }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneColor">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by stoneColor"
            >
              Color
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneColor }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneSize">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by stoneSize"
            >
              Size
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneSize }}
            </td>
          </ng-container>
          <ng-container matColumnDef="labGrown">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by labGrown"
            >
              Lab Grown
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.labGrown ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="traceable">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by traceable"
            >
              Traceable
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.traceable ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="customerProvided">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by customerProvided"
            >
              Customer Provided
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.customerProvided ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="invoicedQty">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by invoicedQty"
            >
              Qty
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoicedQty }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="stoneColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: stoneColumns"
            class="gems-row"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="8">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<swal
  #errorDialog
  title="Daily Metrics Report Error"
  text="There is no data to generate for daily metrics"
  icon="error"
  confirmButtonText="Okay, got it."
  [showConfirmButton]="true"
>
</swal>
