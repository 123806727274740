<div class="product-invoice">
  <div class="total-qty-container">
    <div class="total-qty">
      <h2 style="color: white">Total Quantity:</h2>
      <h2 class="total-qty-value">{{ totalQty }}</h2>
    </div>
  </div>
  <table
    mat-table
    [dataSource]="invoices"
    matSort
    matSortActive="invoicedDate"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="invoicesId">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by invoicesId"
      >
        Invoices Id
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          class="riva-button riva-button-sm riva-button-blue"
          [routerLink]="element.link"
          target="_blank"
        >
          {{ element.invoicesId }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="customerName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by customerName"
      >
        Customer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.customerName }}</td>
    </ng-container>
    <ng-container matColumnDef="metal">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by metal"
      >
        Metal
      </th>
      <td mat-cell *matCellDef="let element">{{ element.metal }}</td>
    </ng-container>
    <ng-container matColumnDef="invoicedDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by invoicedDate"
      >
        Invoiced Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoicedDate | date : 'MM/dd/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="qty">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by qty"
      >
        Qty
      </th>
      <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
    </ng-container>
    <ng-container matColumnDef="price">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by price"
      >
        Price
      </th>
      <td mat-cell *matCellDef="let element">{{ element.price | currency }}</td>
    </ng-container>
    <ng-container matColumnDef="priceExtension">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by priceExtension"
      >
        Price Extension
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.priceExtension | currency }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="6">No Available Data</td>
    </tr>
  </table>
</div>
