import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'reporting-invoice-error-dialog',
  templateUrl: './reporting-invoice-error-dialog.component.html',
  styleUrls: ['./reporting-invoice-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportingInvoiceErrorDialogComponent implements OnInit  {
  invoicesDisplayedColumns: string[] = ['invoiceId'];
  stoneDisplayedColumns: string[] = [
    'stoneId',
    'stoneType',
    'stoneCut',
    'stoneClarity',
    'stoneColor',
    'stoneSize',
  ];

  invoices = [];

  stones = [];

  constructor(
    public dialogRef: MatDialogRef<ReportingInvoiceErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { errors },
  ) {
  }

  ngOnInit(): void {
    this.stones = this.props.errors?.stones ?? [];
    this.invoices = (this.props.errors?.metals ?? [])?.map((m) => ({
      ...m,
      link: `/invoicing-and-shipping/invoicing/${m.invoiceId}`,
    }))
  }
}
