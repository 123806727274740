<table
  mat-table
  [dataSource]="orderDetailsData"
  multiTemplateDataRows
  class="order-detail-list"
  matSort
  matSortActive="entryDate"
  matSortDirection="asc"
>
  <!-- Product name -->
  <ng-container matColumnDef="productName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by productName"
    >
      PRODUCT NAME
    </th>
    <td mat-cell *matCellDef="let element">
      <div
        (click)="onRedirectToProduct($event, element)"
        style="cursor: pointer"
      >
        {{ element.productName }}
      </div>
    </td>
  </ng-container>

  <!-- Size and material -->
  <ng-container matColumnDef="productsInfoId">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by materialCode"
    >
      MATERIAL AND SIZE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.materialCode }} | SZ-{{ element.size }}
    </td>
  </ng-container>

  <ng-container matColumnDef="materialCode">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by materialCode"
    >
      METAL
    </th>
    <td mat-cell *matCellDef="let element">
      <div
        *ngIf="element.primaryMaterialColor && element.secondaryMaterialColor"
        style="display: flex; align-items: center; gap: 1px"
      >
        <div class="multi-material">
          <div
            class="primary-material"
            [ngStyle]="{
              'border-color':
                element.primaryMaterialColor +
                ' transparent transparent transparent'
            }"
          ></div>
          <div
            class="secondary-material"
            [ngStyle]="{
              'border-color':
                'transparent transparent' +
                element.secondaryMaterialColor +
                ' transparent'
            }"
          ></div>
        </div>
        <span>{{ element.materialCode }}</span>
      </div>
      <div
        style="display: flex; align-items: center"
        *ngIf="
          !(element.primaryMaterialColor && element.secondaryMaterialColor)
        "
      >
        <div
          *ngIf="element.materialColor"
          style="
            width: 60px;
            height: 30px;
            border: 3px solid black;
            background-color: rgb(255, 255, 255);
            margin-right: 10px;
          "
          [ngStyle]="{
            'background-color': element.materialColor
          }"
        ></div>
        {{ element.materialCode }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by size"
    >
      SIZE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.size }}
    </td>
  </ng-container>
  <ng-container matColumnDef="chainRawLength">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by chainRawLength"
    >
      LENGTH
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.chainRawLength }}
    </td>
  </ng-container>

  <ng-container matColumnDef="enamel">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by enamel"
    >
      ENAMEL
    </th>
    <td mat-cell *matCellDef="let element">
      <div style="display: flex; align-items: center">
        <div
          *ngIf="element.orderDetailsEnamel?.colorHex"
          style="
            width: 60px;
            height: 30px;
            border: 3px solid black;
            background-color: rgb(255, 255, 255);
            margin-right: 10px;
          "
          [ngStyle]="{
            'background-color': element.orderDetailsEnamel?.colorHex
          }"
        ></div>
        {{ element.enamel }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="stoneText">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by stoneText"
    >
      STONE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.stoneText }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cipo">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by cipo"
    >
      CI-PO
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.cipo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="customized">
    <th mat-header-cell *matHeaderCellDef>CUSTOMIZED</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.customized">
        {{ element.customized ? 'Yes' : 'No' }}
      </span>
    </td>
  </ng-container>

  <!-- Qty Ordered -->
  <ng-container matColumnDef="qtyordered">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by qtyordered"
    >
      ORDERED
    </th>
    <td mat-cell *matCellDef="let element">{{ element.qtyordered }}</td>
  </ng-container>

  <ng-container matColumnDef="backOrdered">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by backOrdered"
    >
      BACK-ORDERED
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.backOrdered }}
    </td>
  </ng-container>

  <!-- Qty Shipped -->
  <ng-container matColumnDef="qtyshipped">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by qtyshipped"
    >
      SHIPPED
    </th>
    <td mat-cell *matCellDef="let element">{{ element.qtyshipped }}</td>
  </ng-container>

  <!-- Qty Invoiced -->
  <ng-container matColumnDef="qtyinvoiced">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by qtyinvoiced"
    >
      INVOICED
    </th>
    <td mat-cell *matCellDef="let element">{{ element.qtyinvoiced }}</td>
  </ng-container>

  <!-- EntryDate -->
  <ng-container matColumnDef="entryDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by entryDate"
    >
      ENTRY DATE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.entryDate | date : 'MM/dd/yyyy' }}
    </td>
  </ng-container>

  <!-- dueDate -->
  <ng-container matColumnDef="dueDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by dueDate"
    >
      DUE DATE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.dueDate | date : 'MM/dd/yyyy' }}
    </td>
  </ng-container>

  <!-- comment -->
  <ng-container matColumnDef="comment">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by comment"
    >
      COMMENT
    </th>
    <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
  </ng-container>

  <ng-container matColumnDef="shipDate">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      start="asc"
      sortActionDescription="Sort by shipDate"
    >
      SHIPPED DATE
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.shipDate | date : 'MM/dd/yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="printAction">
    <th mat-header-cell *matHeaderCellDef>WKO#</th>
    <td mat-cell *matCellDef="let element">
      <div style="display: flex; align-items: center; gap: 8px">
        <button
          class="btn-riva"
          *ngIf="
            element.workOrder?.workOrderId && !element.workOrder?.printedDate
          "
          (click)="onPrintWorkOrder($event, element.workOrder)"
          userPermission
          [elementKey]="orderDetailsFeatureKey.printDetail"
        >
          Print
        </button>
        <button
          class="btn-view"
          *ngIf="
            element.workOrder?.workOrderId && element.workOrder?.printedDate
          "
          (click)="onPrintWorkOrder($event, element.workOrder)"
        >
          {{ element.workOrder.workOrderId }}
        </button>
        <button
          class="btn-riva btn-danger"
          *ngIf="!element.workOrder?.workOrderId && element.bypassWKOs"
          userPermission
          [elementKey]="orderDetailsFeatureKey.byPassWorkOrder"
          style="text-transform: unset; font-size: 14px"
          (click)="onUndoByPassOrder($event, element)"
        >
          Undo Bypass
        </button>
        <ng-container *ngIf="!isOrderOnline">
          <button
            class="btn-riva btn-danger"
            *ngIf="!(element.workOrder?.workOrderId || element.bypassWKOs)"
            userPermission
            [elementKey]="orderDetailsFeatureKey.deleteDetail"
            (click)="onDeleteDetail($event, element)"
            style="text-transform: unset; font-size: 14px"
          >
            Delete
          </button>
          <button
            class="btn-riva btn-warning"
            *ngIf="!(element.workOrder?.workOrderId || element.bypassWKOs)"
            userPermission
            [elementKey]="orderDetailsFeatureKey.byPassWorkOrder"
            (click)="onByPassOrder($event, element)"
            style="text-transform: unset; font-size: 14px"
          >
            Bypass WKO
          </button>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: columnsToDisplay"
    class="order-detail-list-row"
    [class.example-expanded-row]="element.ordersDetailsId === selectedDetailId"
    (click)="onOpenDetail(element)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="example-detail-row"
  ></tr>

  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="columnsToDisplay.length"
    >
      <div
        class="example-element-detail"
        [@detailExpand]="
          element.ordersDetailsId === selectedDetailId
            ? 'expanded'
            : 'collapsed'
        "
      >
        <div *ngIf="element.isOpened" class="example-element-diagram">
          <div class="row">
            <div
              class="col-12"
              *ngIf="element.chainType == null && !element.isFindings"
            >
              <app-orderdetails-item
                [orderDetails]="element"
                [customerId]="_customerId"
                [isEditable]="
                  (!element.workOrder?.workOrderId ||
                    hasAccess(orderDetailsFeatureKey.updateDetail)) &&
                  !element.bypassWKOs
                "
                [isOrderOnline]="isOrderOnline"
                [productList]="productList"
                [customersList]="customersList"
                [materialCodes]="materialCodes"
              ></app-orderdetails-item>
            </div>
            <div
              class="col-12"
              *ngIf="
                element.chainType != null &&
                !element.isFindings &&
                !element.isOtherComponent
              "
            >
              <riva-chain-order
                [orderDetail]="element"
                [isEditable]="
                  (!element.workOrder?.workOrderId ||
                    hasAccess(orderDetailsFeatureKey.updateDetail)) &&
                  !element.bypassWKOs
                "
                [isOrderOnline]="isOrderOnline"
                (onCancel)="onCancelOrderDetail()"
                (onSuccess)="onChainOrderDetailUpdateSuccess()"
              ></riva-chain-order>
            </div>
            <div class="col-12" *ngIf="element.isFindings">
              <riva-findings-order-add
                [orderDetail]="element"
                [isEditable]="
                  (!element.workOrder?.workOrderId ||
                    hasAccess(orderDetailsFeatureKey.updateDetail)) &&
                  !element.bypassWKOs
                "
                [isOrderOnline]="isOrderOnline"
                (onCancel)="expandedElement = null"
                (onSuccess)="onFindingsOrderDetailUpdateSuccess()"
              ></riva-findings-order-add>
            </div>

            <div class="col-12" *ngIf="element.isOtherComponent">
              <riva-other-component-order
                [isEditable]="
                  (!element.workOrder?.workOrderId ||
                    hasAccess(orderDetailsFeatureKey.updateDetail)) &&
                  !element.bypassWKOs
                "
                [isOrderOnline]="isOrderOnline"
                [orderDetail]="element"
                (onCancel)="selectedDetailId = null"
                (onSuccess)="onOtherComponentOrderDetailUpdateSuccess()"
              ></riva-other-component-order>
            </div>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
</table>

<div class="order-details__container" *ngIf="isAddNewOrderDetail">
  <app-new-orderdetails-item
    [orderDetails]="orderDetail"
    [customerId]="_customerId"
    [productList]="productList"
    [customersList]="customersList"
    [materialCodes]="materialCodes"
    (onCancel)="onCancelOrderDetail()"
    (onSuccess)="onOrderDetailSuccess()"
  ></app-new-orderdetails-item>
</div>
<div class="order-details__container" *ngIf="isAddNewChainOrderDetail">
  <riva-chain-order
    [orderDetail]="chainOrderDetail"
    (onCancel)="onCancelChainOrderDetail()"
    (onSuccess)="onChainOrderDetailSuccess()"
    [isEditable]="true"
  ></riva-chain-order>
</div>
<div class="order-details__container" *ngIf="isAddNewFindingsOrderDetail">
  <riva-findings-order-add
    [orderDetail]="findingsOrderDetail"
    (onCancel)="onCancelFindingsOrderDetail()"
    (onSuccess)="onFindingsOrderDetailSuccess()"
  ></riva-findings-order-add>
</div>
<div class="order-details__container" *ngIf="isAddNewOtherComponentOrderDetail">
  <riva-other-component-order
    [isEditable]="true"
    [orderDetail]="otherComponentOrderDetail"
    (onCancel)="onCancelOtherComponentOrderDetail()"
    (onSuccess)="onOtherComponentOrderDetailSuccess()"
  ></riva-other-component-order>
</div>
<div class="action" *ngIf="!isOrderOnline">
  <div
    class="button-group"
    style="margin-top: 15px"
    *ngIf="
      !isAddNewOrderDetail &&
      !(
        isAddNewChainOrderDetail ||
        isAddNewFindingsOrderDetail ||
        isAddNewOtherComponentOrderDetail
      )
    "
  >
    <button
      class="riva-button"
      (click)="onAddNewOrderDetail()"
      userPermission
      [elementKey]="orderDetailsFeatureKey.addOrderDetail"
      style="border-radius: 3px 0 0 3px"
    >
      ADD PRODUCT
    </button>
    <button
      class="riva-button"
      userPermission
      [elementKey]="orderDetailsFeatureKey.addOrderDetail"
      style="
        min-width: unset;
        border-radius: 0 3px 3px 0;
        border-left: 1px solid white;
      "
      [matMenuTriggerFor]="newProduct"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-caret-down-filled"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
          stroke-width="0"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>

  <button
    class="riva-button"
    style="width: 250px; margin-top: 15px"
    (click)="onAddNewChainOrderDetail()"
    *ngIf="
      !isAddNewChainOrderDetail &&
      !(
        isAddNewOrderDetail ||
        isAddNewFindingsOrderDetail ||
        isAddNewOtherComponentOrderDetail
      )
    "
    userPermission
    [elementKey]="orderDetailsFeatureKey.addOrderDetail"
  >
    ADD CHAIN
  </button>
  <button
    class="riva-button"
    style="width: 250px; margin-top: 15px"
    (click)="onAddNewFindingsOrderDetail()"
    *ngIf="
      !isAddNewFindingsOrderDetail &&
      !(
        isAddNewOrderDetail ||
        isAddNewChainOrderDetail ||
        isAddNewOtherComponentOrderDetail
      )
    "
    userPermission
    [elementKey]="orderDetailsFeatureKey.addOrderDetail"
  >
    ADD FINDINGS
  </button>
  <button
    class="riva-button"
    style="width: 250px; margin-top: 15px"
    (click)="onAddNewOtherComponentOrderDetail()"
    *ngIf="
      !isAddNewOtherComponentOrderDetail &&
      !(
        isAddNewOrderDetail ||
        isAddNewChainOrderDetail ||
        isAddNewFindingsOrderDetail
      )
    "
    userPermission
    [elementKey]="orderDetailsFeatureKey.addOrderDetail"
  >
    ADD OTHER COMPONENT
  </button>
</div>

<mat-menu style="max-width: 400px" #newProduct="matMenu" xPosition="before">
  <button
    mat-menu-item
    style="
      font-size: 14px;
      text-transform: unset;
      text-align: left;
      width: 250px;
    "
    (click)="onAddProductByCustomerSku()"
  >
    Customer SKUS
  </button>
</mat-menu>
