<h3 mat-dialog-title>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-circle-x-filled"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#ff4500"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path
      d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
      stroke-width="0"
      fill="#ff4500"
    />
  </svg>
  <span style="padding-left: 16px">End of Day Report Error</span>
</h3>
<mat-dialog-content class="mat-typography">
  <div class="employee-down-time-error-dialog">
    <p class="error-message">
      An error encountered as some employee still has a pending work order.
      Listed below are some employees who checkout but haven't checked in yet.
    </p>
    <div class="table-container">
      <table mat-table [dataSource]="props.names">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="1">No Employee Details</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      padding: 16px;
    "
  >
    <button
      class="btn btn-primary"
      style="width: 150px; height: 40px"
      mat-dialog-close
      autofocus
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
