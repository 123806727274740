import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'employee-down-time-error-dialog',
  templateUrl: './employee-down-time-error-dialog.component.html',
  styleUrls: ['./employee-down-time-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeDownTimeErrorDialogComponent {
  displayedColumns: string[] = ['name'];

  constructor(
    public dialogRef: MatDialogRef<EmployeeDownTimeErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { names: string[] },
  ) {}
}
