import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoneItem } from '../components/open-order-stone-report/stone-receiving-dialog/stone-item';
import {
  ClosingDetail,
  ClosingDetailApi,
  WorkOrderDates,
  WorkOrderDetailForPDF,
  WorkOrderDetailsForPrint,
  WorkOrderUI
} from '../models/work-order';
import { GenericService } from './generic.service';

const getOrderType = (id) => {
  switch (id) {
    case 1:
      return 'Internal';
    case 2:
      return 'Online';
    default:
      return 'Standard';
  }
};

export const buildWorkOrderForUI = (workOrder): WorkOrderUI => {
  const start = DateTime.fromISO(workOrder.dueDate);
  const end = DateTime.local();
  const diff = start.diff(end, 'days').days;
  const isAlmostDue = diff <= 4 && diff > 0 && !workOrder.closedDate;
  const isAlreadyDue = diff <= 0 && !workOrder.closedDate;

  return {
    ...workOrder,
    orderId: workOrder.orderId,
    poInternal: workOrder.poInternal?.toString(),
    dueDate: workOrder.dueDate ? new Date(workOrder.dueDate) : null,
    productId: workOrder.productId,
    productName: workOrder.productName,
    sku: workOrder.sku,
    picturePath: workOrder.productPicturePath,
    workOrdersId: workOrder.workOrdersId,
    createdDate: workOrder.createdDate ? new Date(workOrder.createdDate) : null,
    workOrderDetailsId: workOrder.workOrderDetailsId,
    quantity: workOrder.qty,
    quantityClosed: workOrder.qtyClosed,
    printedDate: workOrder.printedDate ? new Date(workOrder.printedDate) : null,
    releasedDate: workOrder.releasedDate
      ? new Date(workOrder.releasedDate)
      : null,
    dateClosed: workOrder.closedDate ? new Date(workOrder.closedDate) : null,
    isAlmostDue,
    isAlreadyDue,
    materialCode: workOrder.materialCode,
    qtyTotal: workOrder.qtyTotal,
    printColor1: workOrder.printColor1 ? `#${workOrder.printColor1}` : null,
    customized: workOrder.customized ?? false,
    productSku: workOrder.productSku ?? '',
    materialDescription: workOrder.materialDescription ?? '',
    orderType: getOrderType(workOrder.orderType),
    link: `/main/orders/${workOrder.ordersId}`,
    customerName: workOrder.customer?.companyName ?? '',
    customerId: workOrder.customer?.id,
  };
};

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService extends GenericService {
  baseUrl = environment.apiUrl + 'work-order';
  invoicesUrl = environment.apiUrl + 'invoices';
  shipmentsUrl = environment.apiUrl + 'shipments';
  receivingUrl = environment.apiUrl + 'receiving';
  workOrderDetailsUrl = environment.apiUrl + 'work-order-details';

  constructor(http: HttpClient) {
    super(http);
  }

  public getListReport(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/list-report', {
      headers: this.headers,
    });
  }

  public closeWorkOrder(wkoId: number): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + '/close-wko/' + wkoId,
      {},
      { headers: this.headers },
    );
  }

  public closeWorkOrderDetail(wkoDetailId: number): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + '/close-wko-detail/' + wkoDetailId,
      {},
      { headers: this.headers },
    );
  }

  public getWorkOrders(): Observable<WorkOrderUI[]> {
    return this.http
      .get<any>(environment.apiUrl + 'work-orders', {
        headers: this.headers,
      })
      .pipe(map((data) => data.map(buildWorkOrderForUI)));
  }

  public getWorkOrdersByPage(params) {
    return this.http.get<any>(environment.apiUrl + 'work-orders/paged', {
      headers: this.headers,
      params,
      observe: 'response',
    });
  }

  public generateWorkOrders(orderId: number): Observable<WorkOrderUI[]> {
    return this.http.post<any>(
      environment.apiUrl + 'work-orders/generate',
      {},
      { headers: this.headers, params: { orderId } },
    );
  }

  public getWorkOrderPrintDetail(workOrderId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/print', {
      headers: this.headers,
      params: { workOrderId },
    });
  }

  public setWorkOrderPrint(orderId: number): Observable<boolean> {
    return this.http.put<any>(
      environment.apiUrl + 'work-orders/printed',
      {},
      { headers: this.headers, params: { orderId } },
    );
  }
  public setWorkOrderNotPrint(orderId: number): Observable<boolean> {
    return this.http.put<any>(
      environment.apiUrl + 'work-orders/not-printed',
      {},
      { headers: this.headers, params: { orderId } },
    );
  }
  public setWorkOrderRelease(orderId: number): Observable<boolean> {
    return this.http.put<any>(
      environment.apiUrl + 'work-orders/release',
      {},
      { headers: this.headers, params: { orderId } },
    );
  }
  public setWorkOrderClose(orderId: number): Observable<boolean> {
    return this.http.put<any>(
      environment.apiUrl + 'work-orders/close',
      {},
      { headers: this.headers, params: { orderId } },
    );
  }

  public getWorkOrderForPrint(
    workOrderId: number,
  ): Observable<WorkOrderDetailsForPrint> {
    return this.http
      .get<any>(`${environment.apiUrl}print/work-order`, {
        headers: this.headers,
        params: { workOrderId },
      })
      .pipe(
        map((data) => ({
          customized: data?.customized ?? false,
          product: data?.product ?? {},
          material: data.material ?? {},
          orderDetail: data.orderDetails ?? {},
          purchaseOrder: data.purchaseOrder ?? {},
          workOrder: data.workOrder ?? {},
          workOrderDetails: data.workOrderDetails ?? {},
          quantity: data.workOrderDetails?.reduce((total, detail) => {
            return total + (detail.qty ?? 0);
          }, 0),
        })),
      );
  }

  public validateWorkOrderForInvoices(workOrderId: number) {
    return this.http.get<any>(`${this.invoicesUrl}/validate-work-order`, {
      headers: this.headers,
      params: { workOrderId },
    });
  }
  public setWorkOrderInvoices(workOrderId: number) {
    return this.http.post<any>(
      this.invoicesUrl,
      {},
      {
        headers: this.headers,
        params: { workOrderId },
      },
    );
  }
  public validateWorkOrderForShipment(workOrderId: number) {
    return this.http.get<any>(`${this.shipmentsUrl}/validate-work-order`, {
      headers: this.headers,
      params: { workOrderId },
    });
  }
  public setWorkOrderShipments(workOrderId: number) {
    return this.http.post<any>(
      this.shipmentsUrl,
      {},
      {
        headers: this.headers,
        params: { workOrderId },
      },
    );
  }

  public generateWorkOrderPdf(id): Observable<string> {
    return this.http.get(`${environment.apiUrl}work-orders/generate-pdf`, {
      headers: this.headers,
      responseType: 'text',
      params: { id },
    });
  }

  public getUnClosedWorkOrderItems(workOrderId: number) {
    return this.http.get<any>(`${this.receivingUrl}/work-order-items`, {
      headers: this.headers,
      params: { workOrderId },
    });
  }

  public receiveWorkOrderItems(items) {
    return this.http.put(this.receivingUrl, items, {
      headers: this.headers,
    });
  }

  public getOrphanedWorkOrder() {
    return this.http.get<any>(`${environment.apiUrl}work-orders/orphaned`, {
      headers: this.headers,
    });
  }
  public deleteWorkOrder(workOrderId) {
    return this.http.delete<any>(
      `${environment.apiUrl}work-orders/${workOrderId}`,
      {
        headers: this.headers,
      },
    );
  }
  public getClosingDetails(orderId: number): Observable<ClosingDetail[]> {
    return this.http
      .get<ClosingDetailApi[]>(
        `${environment.apiUrl}work-orders/closing-details/${orderId}`,
        { headers: this.headers },
      )
      .pipe(
        map((data) =>
          data.reduce((accum, d) => {
            return [
              ...accum,
              ...d.details.map((detail) => ({
                index: d.index,
                ...detail,
              })),
            ];
          }, []),
        ),
      );
  }

  public updateClosingDetail(closingId, update) {
    return this.http.put(
      `${environment.apiUrl}work-orders-closing/${closingId}`,
      update,
      {
        headers: this.headers,
      },
    );
  }
  public getWorkOrderStones(workOrderId) {
    return this.http
      .get<StoneItem[]>(
        `${environment.apiUrl}work-orders/${workOrderId}/stones`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map((data) =>
          data.reduce((items, d) => {
            const stoneToRelease =
              d.totalStoneToRelease <= 0 ? 0 : d.totalStoneToRelease;
            return [
              ...items,
              {
                ...d,
                carat:
                  d.caratsPerUnit > 0
                    ? parseFloat((stoneToRelease * d.caratsPerUnit).toFixed(4))
                    : 0,
                totalStoneToRelease: stoneToRelease,
                maxQty: stoneToRelease,
                maxCarat:
                  d.caratsPerUnit > 0 ? stoneToRelease * d.caratsPerUnit : 0,
              },
            ];
          }, []),
        ),
      );
  }
  public releaseStoneByWorkOrderId(data) {
    return this.http.put(
      `${environment.apiUrl}work-orders/stone-release`,
      data,
      {
        headers: this.headers,
      },
    );
  }
  public validateStoneRelease(workOrderId) {
    return this.http.get<{ status: number }>(
      `${environment.apiUrl}work-orders/validate-stone-release/${workOrderId}`,
      {
        headers: this.headers,
      },
    );
  }
  public getWorkOrderDates(workOrderId: number) {
    return this.http.get<WorkOrderDates>(
      `${environment.apiUrl}work-orders/dates/${workOrderId}`,
      {
        headers: this.headers,
      },
    );
  }
  public getWorkOrderDetails(workOrderId: number) {
    return this.http.get<WorkOrderDetailForPDF>(
      `${this.workOrderDetailsUrl}/${workOrderId}`,
      {
        headers: this.headers,
      },
    );
  }
  public deleteWorkOrderDetail(workOrderDetailsId ) {
    return this.http.delete<any>(
      `${this.workOrderDetailsUrl}/${workOrderDetailsId}`,
      {
        headers: this.headers,
      },
    );
  }
}
