<div class="riva-reporting-work-order-moved">
  <div class="riva-reporting">
    <div class="selection-section">
      <div class="header">
        <div class="filters">
          <!-- <div>
            <mat-slide-toggle [(ngModel)]="isDateRange" style="color: white"
              >Show date range</mat-slide-toggle
            >
          </div> -->
          <div>
            <riva-date-range-picker
              [startDate]="filters.dateRangeFrom"
              [endDate]="filters.dateRangeTo"
              [isSinglePicker]="!isDateRange"
              (onChange)="onDateRangeChange($event)"
            ></riva-date-range-picker>
          </div>
        </div>
        <button
          class="btn btn-success"
          [disabled]="isDownloadingFile || !(isSelectedAll || isSomeSelected)"
          (click)="onGenerateReport()"
        >
          End of Day Report
        </button>
      </div>
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="color: white; width: 100%"
      >
        <mat-label>Search...</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="onFilterChange()"
        />
      </mat-form-field>
      <div class="table-container">
        <table
          mat-table
          matSort
          #employee
          #employeeSort="matSort"
          [dataSource]="accounts"
          matSortActive="fullName"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="selection">
            <th mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <mat-checkbox
                  class="example-margin"
                  [checked]="isSelectedAll"
                  [indeterminate]="isSomeSelected"
                  (change)="selectAll($event.checked)"
                >
                </mat-checkbox>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <mat-checkbox
                  [(ngModel)]="element.selected"
                  (ngModelChange)="onSelect()"
                ></mat-checkbox>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="fullName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by fullName"
            >
              Name
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="onSelectUser(element)"
            >
              {{ element.fullName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="employeeID">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by employeeID"
            >
              Employee ID
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="onSelectUser(element)"
            >
              {{ element.employeeID || '–' }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsForEmployee; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsForEmployee"
            class="gems-row"
            [ngClass]="{
              'is-selected': row.usersID === selectedUser?.usersID
            }"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="3">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="details-container">
      <div>
        <div class="work-order-details">
          <div
            class="field"
            style="
              margin-bottom: 16px;
              font-size: 26px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
            "
          >
            {{ selectedUser?.fullName }}
          </div>
          <div class="details-table-section">
            <nz-alert
              nzType="info"
              nzMessage="Note that this information includes the PD Movement."
              nzShowIcon
            >
            </nz-alert>
            <div class="table-container">
              <table
                mat-table
                matSort
                #workOrder
                #workOrderSort="matSort"
                [dataSource]="employeeDownTime"
                matSortActive="scanInTime"
                matSortDirection="desc"
              >
                <ng-container matColumnDef="scanInTime">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by scanInTime"
                  >
                    Date
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.scanInTime | date : 'MM/dd/yyyy' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalMinutesActive">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by totalMinutesActive"
                  >
                    Total Minutes Active
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.totalMinutesActive }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="workDayDifference">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by workDayDifference"
                  >
                    Workday Difference
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.workDayDifference }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsForWorkOrder; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsForWorkOrder"
                  class="gems-row"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center" colspan="3">
                    No Available Data
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<swal
  #errorDialog
  title="Employee Down-Time Report Error"
  text="There is no data to generate for employee down-time"
  icon="error"
  confirmButtonText="Okay, got it."
  [showConfirmButton]="true"
>
</swal>
