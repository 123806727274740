import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import orderBy from 'lodash/orderBy';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { User } from 'src/app/core/authentication/user';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { ProductRoutingCodes } from 'src/app/models/product';
import { JiraService } from 'src/app/services/jira.service';
import { sizeMapper } from 'src/app/services/product-bom.service';
import { ProductsRoutingService } from 'src/app/services/products-routing.service';
import { ScanService } from 'src/app/services/scan.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { getDisplayText } from '../riva-gems/stone.mapper';
import { getOrderDetailsSizeDisplayText } from '../work-order-view/work-order-view.component';
import {
  WorkOrderJiraDetail,
  WorkOrderMovementHistory,
  WorkOrderTracking,
} from './model';

@Component({
  selector: 'app-work-order-tracking',
  templateUrl: './work-order-tracking.component.html',
  styleUrls: ['./work-order-tracking.component.scss'],
  animations: [fadeAnimation],
})
export class WorkOrderTrackingComponent implements OnInit {
  isScanning = false;
  hasWorkOrderError = false;
  hasEmployeeError = false;
  hasRoutingError = false;
  successMovement = false;
  hasMovementError = false;
  hasJiraScanError = false;
  jiraScanErrorMessage = '';
  employeeInProgressWorkOrderId = 0;
  code = '';
  workOrderTracking: WorkOrderTracking | null = null;
  trackingDetails: {
    workOrderId: number;
    customerId: string;
  };
  materialLabels = Array.from({ length: 20 }, (_, i) => i + 1);
  productMaterialStyle: string = '';
  employees: User[];
  routings: ProductRoutingCodes[];
  selectedRouting: ProductRoutingCodes;
  selectedEmployee: User;
  workOrderId: number;
  jiraId: string;
  pdWorkOrderMovementHistory: WorkOrderMovementHistory[];
  sizeMapperInstance = sizeMapper;
  stoneMapperInstance = getDisplayText;
  stoneVariationSizeMapperInstance = getOrderDetailsSizeDisplayText;
  workOrderMovementHistory: WorkOrderMovementHistory[] = [];
  unclosedWorkOrder: WorkOrderMovementHistory | null = null;
  scanMovementStatus: 'ScanIn' | 'ScanOut' | null = null;
  employeeScannedSuccess = false;
  bannerMessage = 'SCAN EMPLOYEE ID';
  filteredProductRoutingCodes: Observable<ProductRoutingCodes[]>;
  productRoutingControl = new FormControl();
  selectedProductRouting: ProductRoutingCodes;
  jiraDetail: WorkOrderJiraDetail | null = null;

  constructor(
    private scanService: ScanService,
    private userService: UserService,
    private productsRoutingService: ProductsRoutingService,
    private workOrderMovementService: WorkOrderMovementService,
    private userPermissionService: UserPermissionService,
    private jiraService: JiraService,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.workOrderTracking);
  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe((data) => {
      this.employees = data;
    });
    this.productsRoutingService.getProductRoutingCodes().subscribe((data) => {
      this.routings = data;
      this.initiateFilters();
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (this.isScanning) return;
    const isAlphanumeric = /^[a-zA-Z0-9-]$/.test(event.key);
    const isValidKey = event.key === 'Enter' || isAlphanumeric;
    if (!isValidKey) return;

    if (event.key === 'Enter') {
      this.processingRequest();
    } else {
      this.code = `${this.code}${event.key}`;
    }
  }

  processingRequest() {
    if (this.code.toLowerCase().indexOf('pd-') >= 0) {
      this.onScanJira(this.code);
    } else if (!isNaN(Number(this.code)) && !this.workOrderId) {
      const workOrderId = parseInt(this.code);
      this.onScanWorkOrder(workOrderId);
    } else if (
      this.bannerMessage === 'SCAN CLOSE TO FINISH' &&
      this.code?.toLocaleLowerCase() === 'close' &&
      (this.workOrderId > 0 || this.jiraId !== '')
    ) {
      if (this.jiraId !== '') {
        this.onScanCloseJiraTracing();
      } else {
        this.onScanCloseTracing();
      }
    } else if (
      this.code &&
      isNaN(Number(this.code)) &&
      (this.workOrderId > 0 || this.jiraId !== '') &&
      this.unclosedWorkOrder == null
    ) {
      const code = this.code.toLowerCase();
      const isEmployee = code.indexOf('riva') >= 0;
      const isRouting = code.indexOf('rout') >= 0;
      if (isEmployee && this.selectedEmployee == null) {
        this.onScanEmployee(code);
      } else if (isRouting && this.selectedEmployee?.usersID > 0) {
        if (this.jiraId !== '') {
          this.onScanRoutingJira(code);
        } else {
          this.onScanRouting(code);
        }
      } else {
        this.code = '';
      }
    } else {
      this.code = '';
    }
  }

  onScanWorkOrder(workOrderId) {
    this.workOrderId = workOrderId;
    this.isScanning = true;
    this.selectedRouting = null;
    this.selectedEmployee = null;
    this.unclosedWorkOrder = null;
    this.scanMovementStatus = null;
    forkJoin([
      this.scanService.scanWorkOrder(workOrderId),
      this.workOrderMovementService.getWorkOrderMovement(workOrderId),
    ]).subscribe(
      ([data, history]) => {
        if (!data) {
          this.hasWorkOrderError = true;
          return;
        }
        this.workOrderMovementHistory = orderBy(
          history,
          'workOrdersMovementID',
          'asc',
        );

        if (history.length > 0) {
          const lastWorkOrderScanned = history[history.length - 1] ?? null;
          if (
            lastWorkOrderScanned?.workOrdersMovementID > 0 &&
            !lastWorkOrderScanned?.scanInTime
          ) {
            this.unclosedWorkOrder = lastWorkOrderScanned;
            const currentUserId = lastWorkOrderScanned?.usersID;
            this.selectedEmployee = this.employees.find(
              (e) => e.usersID === currentUserId,
            );
            this.bannerMessage = 'SCAN CLOSE TO FINISH';
          } else {
            this.bannerMessage = 'SCAN EMPLOYEE ID';
          }
        } else {
          this.bannerMessage = 'SCAN EMPLOYEE ID';
        }

        this.code = '';
        this.workOrderTracking = data;
        this.workOrderTracking.qty = data.workOrderDetails.reduce(
          (total, w) => total + w.qty,
          0,
        );
        this.workOrderTracking.enamels =
          data.enamels?.filter((e) => !e.variation) ?? [];
        const enamelVariations =
          data.workOrderDetails?.reduce((accum, detail) => {
            if (
              detail.orderDetails.enamel?.colorHex &&
              !this.workOrderTracking.enamels.some(
                (e) => e.enamelSku === detail.orderDetails.enamel?.enamelSku,
              )
            ) {
              return [...accum, detail.orderDetails.enamel];
            }
            return accum;
          }, []) ?? [];
        this.workOrderTracking.enamels = [
          ...this.workOrderTracking.enamels,
          ...enamelVariations,
        ];
        const primaryColor =
          data.primaryMaterial?.printColor1 ?? data.material?.printColor1;
        this.productMaterialStyle = `repeating-linear-gradient(
          90deg,
          #${primaryColor},
          #${primaryColor} 200px,
          #${data.secondaryMaterial?.printColor1 ?? primaryColor} 200px,
          #${data.secondaryMaterial?.printColor1 ?? primaryColor} 400px
        )`;
        this.isScanning = false;
        this.workOrderTracking.stoneVariations = data.workOrderDetails.reduce(
          (stones, w) => {
            if (
              w.orderDetails.stone == null ||
              stones.some((s) => s.stonesId === w.orderDetails.stone?.stonesId)
            )
              return stones;
            return [...stones, w.orderDetails.stone];
          },
          [],
        );
      },
      () => {
        this.hasWorkOrderError = true;
      },
    );
  }

  onScanEmployee(employeeId) {
    this.isScanning = true;
    this.employeeInProgressWorkOrderId = 0;
    const selectedEmployee = this.employees.find(
      (e) => e.employeeID?.toLowerCase() === employeeId,
    );
    if (selectedEmployee == null) {
      this.hasEmployeeError = true;
    } else {
      this.workOrderMovementService
        .getWorkOrderMovementInProgressByUser(selectedEmployee.usersID)
        .subscribe((workOrderId) => {
          this.selectedEmployee = selectedEmployee;
          if (workOrderId > 0) {
            this.hasEmployeeError = true;
            this.employeeInProgressWorkOrderId = workOrderId;
            return;
          }
          this.isScanning = false;
          this.code = '';
          this.bannerMessage = 'SCAN ROUTING CODE';
          this.employeeScannedSuccess = true;
          setTimeout(() => {
            this.employeeScannedSuccess = false;
          }, 2000);
        });
    }
  }

  onScanJira(code) {
    this.isScanning = true;
    this.jiraService.scan(code).subscribe(
      (data = {}) => {
        this.jiraDetail = {
          name: data.summary ?? '[No Title]',
          picPath: data.defaultImage ?? 'assets/images/no-image.png',
          status: data.status ?? '[No Status]',
          description: data.description ?? { content: [] },
        };
        this.jiraId = code;
        this.code = '';
        this.getPdWorkOrderMovement();
      },
      (response) => {
        this.hasJiraScanError = true;
        this.jiraScanErrorMessage = response?.error?.errorMessages?.join(' ');
      },
    );
  }
  getPdWorkOrderMovement() {
    this.workOrderMovementService
      .getPdWorkOrderMovement(this.jiraId)
      .subscribe((data) => {
        if (!data) {
          this.hasWorkOrderError = true;
          return;
        }
        this.isScanning = false;
        this.pdWorkOrderMovementHistory = orderBy(
          data,
          'pdWorkMovementID',
          'asc',
        );
        if (data.length > 0) {
          const lastWorkOrderScanned = data[data.length - 1] ?? null;
          if (
            lastWorkOrderScanned?.pdWorkMovementID > 0 &&
            !lastWorkOrderScanned?.scanInTime
          ) {
            this.unclosedWorkOrder = lastWorkOrderScanned;
            const currentUserId = lastWorkOrderScanned?.usersID;
            this.selectedEmployee = this.employees.find(
              (e) => e.usersID === currentUserId,
            );
            this.bannerMessage = 'SCAN CLOSE TO FINISH';
          } else {
            this.bannerMessage = 'SCAN EMPLOYEE ID';
          }
        } else {
          this.bannerMessage = 'SCAN EMPLOYEE ID';
        }
      });
  }

  onScanRouting(code) {
    const routingCodesId = Number.parseInt(code.replace('rout', ''));
    this.selectedRouting = this.routings.find(
      (r) => r.routingCodesId === routingCodesId,
    );
    this.isScanning = true;
    if (this.selectedRouting == null) {
      this.hasRoutingError = true;
    } else {
      this.code = '';
      this.workOrderMovementService
        .setWorkOrderMovement({
          workOrdersID: this.workOrderId,
          routingCodesID: this.selectedRouting.routingCodesId,
          userId: this.selectedEmployee.usersID,
        })
        .subscribe(
          () => {
            this.successMovement = true;
            this.selectedEmployee = null;
            this.selectedRouting = null;
            this.workOrderTracking = null;
            this.scanMovementStatus = 'ScanOut';
            setTimeout(() => {
              this.resetScan();
            }, 1000);
          },
          () => {
            this.hasMovementError = true;
          },
        );
    }
  }
  onScanRoutingJira(code) {
    const routingCodesId = Number.parseInt(code.replace('rout', ''));
    this.selectedRouting = this.routings.find(
      (r) => r.routingCodesId === routingCodesId,
    );
    this.isScanning = true;
    if (this.selectedRouting == null) {
      this.hasRoutingError = true;
    } else {
      this.code = '';
      this.workOrderMovementService
        .setPdWorkOrderMovement({
          jirA_ID: this.jiraId,
          usersID: this.selectedEmployee.usersID,
          routingCodesID: this.selectedRouting.routingCodesId,
        })
        .subscribe(
          () => {
            this.successMovement = true;
            this.selectedEmployee = null;
            this.selectedRouting = null;
            this.jiraDetail = null;
            this.scanMovementStatus = 'ScanOut';
            setTimeout(() => {
              this.resetScan();
            }, 1000);
          },
          () => {
            this.hasMovementError = true;
          },
        );
    }
  }
  onScanCloseJiraTracing() {
    this.isScanning = true;
    this.code = '';
    this.workOrderMovementService
      .setPdWorkOrderMovement({
        jirA_ID: this.jiraId,
      })
      .subscribe(
        () => {
          this.successMovement = true;
          this.selectedEmployee = null;
          this.selectedRouting = null;
          this.workOrderTracking = null;
          this.scanMovementStatus = 'ScanIn';
          setTimeout(() => {
            this.resetScan();
          }, 1000);
        },
        () => {
          this.hasMovementError = true;
        },
      );
  }

  onScanCloseTracing() {
    this.isScanning = true;
    this.code = '';
    this.workOrderMovementService
      .setWorkOrderMovement({
        workOrdersID: this.workOrderId,
      })
      .subscribe(
        () => {
          this.successMovement = true;
          this.selectedEmployee = null;
          this.selectedRouting = null;
          this.workOrderTracking = null;
          this.scanMovementStatus = 'ScanIn';
          setTimeout(() => {
            this.resetScan();
          }, 1000);
        },
        () => {
          this.hasMovementError = true;
        },
      );
  }

  onCloseErrorMessage() {
    if (this.hasEmployeeError) {
      this.selectedEmployee = null;
      this.employeeScannedSuccess = false;
    }
    this.code = '';
    this.isScanning = false;
    this.hasWorkOrderError = false;
    this.hasEmployeeError = false;
    this.hasRoutingError = false;
    this.hasMovementError = false;
    this.successMovement = false;
    this.scanMovementStatus = null;
    this.employeeInProgressWorkOrderId = 0;
    this.hasJiraScanError = false;
    this.jiraScanErrorMessage = '';
  }
  resetScan() {
    this.selectedEmployee = null;
    this.selectedRouting = null;
    this.workOrderTracking = null;
    this.workOrderId = 0;
    this.jiraId = '';
    this.jiraDetail = null;
    this.bannerMessage = '';
    this.onCloseErrorMessage();
  }
  onSelectProductRoutingCode(productRoutingCode: ProductRoutingCodes) {
    this.selectedRouting = productRoutingCode;
    this.isScanning = true;
    this.code = '';
    this.workOrderMovementService
      .setWorkOrderMovement({
        workOrdersID: this.workOrderId,
        routingCodesID: this.selectedRouting.routingCodesId,
        userId: this.selectedEmployee.usersID,
      })
      .subscribe(
        () => {
          this.successMovement = true;
          this.selectedEmployee = null;
          this.selectedRouting = null;
          this.workOrderTracking = null;
          this.scanMovementStatus = 'ScanOut';
          setTimeout(() => {
            this.resetScan();
          }, 1000);
        },
        () => {
          this.hasMovementError = true;
        },
      );
  }
  onSelectProductRoutingCodeForJira(productRoutingCode: ProductRoutingCodes) {
    this.selectedRouting = productRoutingCode;
    this.isScanning = true;
    this.code = '';
    this.workOrderMovementService
      .setPdWorkOrderMovement({
        jirA_ID: this.jiraId,
        usersID: this.selectedEmployee.usersID,
        routingCodesID: this.selectedRouting.routingCodesId,
      })
      .subscribe(
        () => {
          this.successMovement = true;
          this.selectedEmployee = null;
          this.selectedRouting = null;
          this.jiraDetail = null;
          this.scanMovementStatus = 'ScanOut';
          setTimeout(() => {
            this.resetScan();
          }, 1000);
        },
        () => {
          this.hasMovementError = true;
        },
      );
  }
  displayFn(item: ProductRoutingCodes): string {
    return item?.activityCode ?? '';
  }
  initiateFilters() {
    this.filteredProductRoutingCodes =
      this.productRoutingControl.valueChanges.pipe(
        startWith(this.selectedProductRouting?.activityCode),
        map((value) => this._filterProductRoutingCode(value)),
      );
  }
  private _filterProductRoutingCode(name: string): ProductRoutingCodes[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.routings.filter(
        (option) =>
          option.activityCode.toLowerCase().includes(filterValue) ||
          option.department.toLowerCase().includes(filterValue) ||
          option.activityDesc.toLowerCase().includes(filterValue),
      );
    }
    return this.routings;
  }
}
