import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderDetailsEnamel } from 'src/app/models/orders.model';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class OrderdetailsService extends GenericService {
  baseUrl = environment.apiUrl + 'orderdetails';
  chainUrl = `${environment.apiUrl}order-details/chain`;
  orderDetailAltUrl = `${environment.apiUrl}order-details-alt`;

  constructor(http: HttpClient) {
    super(http);
  }

  getList(): Observable<any> {
    return this.http.get(this.baseUrl + '/list', { headers: this.headers });
  }

  addupdate(orderDetails: any): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', orderDetails, {
      headers: this.headers,
    });
  }

  delete(orderDetailsId: number): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + orderDetailsId, {
      headers: this.headers,
    });
  }

  setOrderDetailEnamel(orderDetailEnamel: Partial<OrderDetailsEnamel>) {
    return this.http.post(
      `${environment.apiUrl}order-details-enamel`,
      orderDetailEnamel,
      { headers: this.headers },
    );
  }

  setOrderDetailsStone(stone: any): Observable<any> {
    return this.http.post(this.baseUrl + '/stones', stone, {
      headers: this.headers,
    });
  }

  setOrderDetailsChain(chain: any): Observable<any> {
    return this.http.post(this.chainUrl, chain, {
      headers: this.headers,
    });
  }
  patchOrderDetails(id, params): Observable<any> {
    return this.http.patch(`${this.baseUrl}/${id}`, {}, {
      headers: this.headers,
      params,
    });
  }
  patchOrderDetailsAlt(id, params): Observable<any> {
    return this.http.patch(`${this.orderDetailAltUrl}/${id}`, {}, {
      headers: this.headers,
      params,
    });
  }
}
