import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DateTime } from 'luxon';
import {
  AnalyticsMaterial,
  AnalyticsService,
  InvoicedStone,
} from 'src/app/services/analytics.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { ReportingInvoiceErrorDialogComponent } from './reporting-invoice-error-dialog/reporting-invoice-error-dialog.component';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}
@Component({
  selector: 'riva-reporting-invoice',
  templateUrl: './reporting-invoice.component.html',
  styleUrls: ['./reporting-invoice.component.scss'],
})
export class ReportingInvoiceComponent implements OnInit {
  @ViewChild('materialsSort', { read: MatSort, static: true })
  materialSort: MatSort;
  @ViewChild('stonesSort', { read: MatSort, static: true }) stoneSort: MatSort;
  @ViewChild('errorDialog')
  public readonly errorDialog!: SwalComponent;

  filters: Filters = {
    dateRangeFrom: DateTime.now().toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.now().toFormat('yyyy-MM-dd'),
  };
  isDateRange = false;

  materialList = new MatTableDataSource<AnalyticsMaterial>([]);
  stoneList = new MatTableDataSource<InvoicedStone>([]);
  totalInvoiced: number = 0;
  displayedColumns = ['description', 'totalWeightShipped'];
  stoneColumns = [
    'stoneType',
    'stoneCut',
    'stoneClarity',
    'stoneColor',
    'stoneSize',
    'labGrown',
    'traceable',
    'customerProvided',
    'invoicedQty',
  ];
  isGenerating = false;

  constructor(
    private workOrderMovementService: WorkOrderMovementService,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getReports();
  }
  ngAfterViewInit() {
    this.materialList.sort = this.materialSort;
    this.stoneList.sort = this.stoneSort;
  }

  getReports() {
    this.getAnalyticsMaterial();
    this.getAnalyticsInvoiced();
    this.getAnalyticsInvoicedStones();
  }
  getAnalyticsMaterial() {
    this.analyticsService
      .getAnalyticsInvoicedWeights({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe((data) => {
        this.materialList.data = data.filter((d) => d.totalWeightShipped > 0);
      });
  }
  getAnalyticsInvoiced() {
    this.analyticsService
      .getAnalyticsInvoicedTimePeriod({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe(({ totalInvoiced }) => {
        this.totalInvoiced = totalInvoiced;
      });
  }
  getAnalyticsInvoicedStones() {
    this.analyticsService
      .getAnalyticsInvoicedStones({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe((data) => {
        this.stoneList.data = data;
      });
  }
  onSetDateRange(config) {
    this.filters.dateRangeFrom = config.start;
    this.filters.dateRangeTo = config.end;
    this.getReports();
  }
  onSetDate() {
    this.filters.dateRangeFrom = DateTime.fromJSDate(
      new Date(this.filters.dateRangeFrom),
    ).toFormat('yyyy-MM-dd');
    this.filters.dateRangeTo = DateTime.fromJSDate(
      new Date(this.filters.dateRangeFrom),
    ).toFormat('yyyy-MM-dd');
    this.getReports();
  }

  onGenerateReport() {
    this.isGenerating = true;
    this.workOrderMovementService
      .getDailyMetricsSummaryReport(
        this.filters.dateRangeFrom,
        this.filters.dateRangeTo,
      )
      .subscribe(
        (response) => {
          if (response == null) {
            setTimeout(() => {
              this.errorDialog.fire();
            }, 100);
            this.isGenerating = false;
            return;
          }

          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const selectedDate = DateTime.fromJSDate(new Date(this.filters.dateRangeFrom))
          a.download = `${selectedDate.toFormat("MM.dd.yyyy")} ${selectedDate.weekdayLong} Daily Metrics.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.isGenerating = false;
        },
        (errors) => {
          this.isGenerating = false;
          this.dialog.open(ReportingInvoiceErrorDialogComponent, {
            disableClose: true,
            maxWidth: '800px',
            width: '100%',
            data: {
              errors,
            },
          });
        },
      );
  }
}
