import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENAMEL_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/enamel-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { Enamel } from 'src/app/models/enamel';
import { Suppliers } from 'src/app/models/suppliers.model';
import { EnamelService } from 'src/app/services/enamel.service';
import { getTypeLabel } from 'src/app/services/generic.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { GenericComponent } from '../generic/generic.component';

@Component({
  selector: 'app-enamel',
  templateUrl: './enamel.component.html',
  styleUrls: ['./enamel.component.scss'],
})
export class EnamelComponent extends GenericComponent implements AfterViewInit {
  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private enamelService: EnamelService,
    private supplierService: SupplierService,
    private userPermissionService: UserPermissionService,
  ) {
    super(loadService, alertService);
    this.load();
    this.userPermissionService.checkPagePermission(PAGE_NAME.enamel);
  }
  @ViewChild(MatSort) sort: MatSort;

  enamels = new MatTableDataSource<Enamel>([]);
  suppliers: Suppliers[] = [];
  getTypeLabel = getTypeLabel;
  featureKey = ENAMEL_FEATURE_KEY;
  displayedColumns: string[] = [
    'enamelId',
    'enamelName',
    'pantoneColor',
    'hardnessDisplayText',
    'supplierDisplayName',
    'colorHex',
  ];

  ngAfterViewInit(): void {
    this.enamels.sort = this.sort;
    this.enamels.filterPredicate = (e: Enamel, filterValue: string) => {
      const { search } = JSON.parse(filterValue);
      return (
        e.enamelName?.toLowerCase().includes(search.toLowerCase()) ||
        e.pantoneColor?.toLowerCase().includes(search.toLowerCase()) ||
        e.colorHex?.toLowerCase().includes(search.toLowerCase()) ||
        e.supplierDisplayName
          ?.toLocaleLowerCase()
          .includes(search.toLowerCase()) ||
        e.hardnessDisplayText
          ?.toLocaleLowerCase()
          .includes(search.toLowerCase())
      );
    };
  }

  load() {
    this.loadService.loadContent(true);
    this.enamel = new Enamel();

    forkJoin([this.enamelService.getList(), this.supplierService.getList()])
      .pipe(
        map(([enamelList, supplierList]) => {
          let enamelsResult = enamelList;
          let suppliersResult = supplierList;
          return { enamelsResult, suppliersResult };
        }),
      )
      .subscribe(
        (data) => {
          this.suppliers = data.suppliersResult;
          this.enamels.data = data.enamelsResult?.map((e) => ({
            ...e,
            supplierDisplayName: this.supplierName(e.supplierId),
            hardnessDisplayText: getTypeLabel(e.hardness),
          }));
          if (this.enamels.data.length != 0) {
            this.selectItem(this.enamels.data[0]);
          }
          this.loadService.loadContent(false);
        },
        (error) => {
          this.alertService.error(error.statusText);
        },
      );
  }

  searchString = '';
  filteredEnamels: Enamel[] = [];

  searchTimeout() {
    this.enamels.filter = JSON.stringify({
      search: this.searchString,
    });
  }

  enamel: Enamel = new Enamel();

  selectItem(enamel: Enamel) {
    if (!this.editMode) {
      this.enamel.enamelId = enamel.enamelId;
      this.enamel.enamelSku = enamel.enamelSku;
      this.enamel.enamelName = enamel.enamelName;
      this.enamel.pantoneColor = enamel.pantoneColor;
      this.enamel.hardness = enamel.hardness;
      this.enamel.colorHex = enamel.colorHex;
      this.enamel.supplierId = enamel.supplierId;
    }
  }

  editMode: boolean = false;

  toggleEdit(edit: boolean) {
    this.editMode = edit;
  }

  save() {
    this.enamelService.post(this.enamel, '').subscribe((result) => {
      this.load();
      this.editMode = false;
    });
  }

  cancelChanges() {
    this.enamel = new Enamel();
    this.enamels.data = [];
    this.filteredEnamels = [];
    this.editMode = false;
    this.load();
  }

  new() {
    this.enamel = new Enamel();
    this.editMode = true;
  }

  supplierName(supplierId: number) {
    if (this.suppliers.length != 0) {
      let data = this.suppliers.find((x) => x.suppliersId == supplierId);
      if (data != undefined) {
        let supplierName = '(' + data.supplierId + ') ' + data.companyName;
        return supplierName;
      }
    }
    return '';
  }

  // applyColorBand(enamel: Enamel) {
  //   let index = this.enamels.data.indexOf(enamel);
  //   if (index % 2 == 0 || index == 0) {
  //     return false;
  //   }
  //   return true;
  // }
}
