<div style="margin-top: 10px">
  <div class="row">
    <div class="col">
      <div class="row search-panel">
        <div class="col">
          <input
            [(ngModel)]="searchString"
            (keyup)="searchTimeout()"
            class="form-control search-text search-field"
            type="text"
            placeholder="SEARCH"
          />
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col-2" style="color: white; line-height: 50px">
          Items: {{ enamels.filteredData.length }}
        </div>
      </div>

      <div class="table-row row">
        <div class="table-container col">
          <table
            mat-table
            [dataSource]="enamels"
            matSort
            matSortActive="enamelId"
            matSortDirection="asc"
          >
            <ng-container matColumnDef="enamelId">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by enamelId"
              >
                ID
              </th>
              <td mat-cell *matCellDef="let element">{{ element.enamelId }}</td>
            </ng-container>
            <ng-container matColumnDef="enamelName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by enamelName"
              >
                Name
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="display: flex; align-items: center; gap: 8px">
                  <div
                    style="width: 30px; height: 30px"
                    [ngStyle]="{ 'background-color': element.colorHex }"
                  ></div>
                  <div>{{ element.enamelName }}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="pantoneColor">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by pantoneColor"
              >
                Pantone Color
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.pantoneColor }}
              </td>
            </ng-container>
            <ng-container matColumnDef="hardnessDisplayText">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by hardnessDisplayText"
              >
                Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.hardnessDisplayText }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierDisplayName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by supplierDisplayName"
              >
                Supplier
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supplierDisplayName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="colorHex">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by colorHex"
              >
                ColorHex
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.colorHex }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
              style="background-color: white"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
                'row-data-active': row.enamelId === enamel.enamelId
              }"
              (click)="selectItem(row)"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="6">
                No Available Data
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <fieldset userPermission [elementKey]="featureKey.create">
            <button class="btn-confirm" (click)="new()" [disabled]="editMode">
              New <span class="fa fa-plus"></span>
            </button>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="row">
        <div
          class="color-preview"
          [ngStyle]="{ 'background-color': enamel.colorHex }"
        ></div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-8">
          <label class="col-sm-2 col-form-label">Name</label>
          <div class="col-sm-12">
            <input
              [(ngModel)]="enamel.enamelName"
              type="text"
              class="form-control"
              placeholder="ENAMEL NAME"
              [disabled]="!editMode"
            />
          </div>
        </div>
        <div class="form-group col-md-4">
          <label class="col-sm-2 col-form-label">Pantone Color</label>
          <div class="col-sm-12">
            <input
              [(ngModel)]="enamel.pantoneColor"
              type="text"
              class="form-control"
              placeholder="PANTONE COLOR"
              [disabled]="!editMode"
            />
          </div>
        </div>
        <div class="form-group col-md form-check">
          <label class="col-sm-2 col-form-label">Type</label>
          <div class="col-sm-12">
            <select
              [(ngModel)]="enamel.hardness"
              class="custom-select form-control"
              [disabled]="!editMode"
            >
              <option [value]="0">Soft</option>
              <option [value]="1">HyCeram</option>
              <option [value]="2">Hard</option>
            </select>
          </div>
        </div>
        <div class="form-group col-md">
          <label class="col-sm-2 col-form-label">suppliers</label>
          <div class="col-sm-12">
            <select
              [(ngModel)]="enamel.supplierId"
              class="custom-select form-control"
              [disabled]="!editMode"
            >
              <option [value]="0" disabled>- Select -</option>
              <option
                *ngFor="let item of suppliers"
                value="{{ item.suppliersId }}"
              >
                ({{ item.supplierId }}) {{ item.companyName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-md">
          <label class="col-sm-2 col-form-label"
            >Color Hex: {{ enamel.colorHex }}</label
          >
          <div class="col-sm-12">
            <input
              [(ngModel)]="enamel.colorHex"
              type="color"
              class="form-control"
              placeholder="COLOR HEX"
              [disabled]="!editMode"
            />
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button class="btn-confirm" (click)="save()" [disabled]="!editMode">
            Save
          </button>
        </div>
        <div class="col">
          <fieldset userPermission [elementKey]="featureKey.edit">
            <button
              class="btn-confirm"
              (click)="toggleEdit(true)"
              [disabled]="editMode || !enamel.enamelId"
            >
              Edit
            </button>
          </fieldset>
        </div>
        <div class="col">
          <button
            class="btn-cancel"
            (click)="cancelChanges()"
            [disabled]="!editMode"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
