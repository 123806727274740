import { PAGE_NAME } from './pages';

export const WORK_ORDER_MOVEMENT_FEATURE_KEY = {
  cancelOpenWorkOrder: 'CANCEL_WORK_ORDER',
};

export const workOrderMovementPermission = {
  [PAGE_NAME.workOrderMovement]: {
    SuperUser: {
      [WORK_ORDER_MOVEMENT_FEATURE_KEY.cancelOpenWorkOrder]: 'access',
    },
    User: {
      [WORK_ORDER_MOVEMENT_FEATURE_KEY.cancelOpenWorkOrder]: 'hidden',
    },
    Viewer: {
      [WORK_ORDER_MOVEMENT_FEATURE_KEY.cancelOpenWorkOrder]: 'hidden',
    },
  },
};
