import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ProductSizes } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';

@Component({
  templateUrl: './product-sizes-dialog.component.html',
  styleUrls: ['./product-sizes-dialog.component.scss'],
})
export class ProductSizesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { productSizes: ProductSizes[] },
    public dialogRef: MatDialogRef<ProductSizesDialogComponent>,
    private productService: ProductService,
    private _confirmationService: ConfirmationService,
  ) {}

  isSaving = false;
  displayedColumns = ['size', 'newSize'];

  onSave() {
    this._confirmationService
      .showConfirmation({
        title: 'Update Size Names',
        content: 'Are you sure you want to update the size names?',
        confirmLabel: 'Yes, update it.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.isSaving = true;
        const request = this.data.productSizes.reduce((accum, size) => {
          if ((size.newSize ?? '') === '') return accum;
          return [
            ...accum,
            this.productService.setProductSize({
              ...size,
              size: size.newSize,
            }),
          ];
        }, []);

        forkJoin(request).subscribe(() => {
          this.dialogRef.close(true);
        });
      });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }

  get isFormValid() {
    return this.data.productSizes.some((s) => (s.newSize ?? '') !== '');
  }
}
