<div class="product-order">
  <div class="total-qty-container">
    <div class="total-qty">
      <h2 style="color: white">Total Quantity Ordered:</h2>
      <h2 class="total-qty-value">{{ totalOrders }}</h2>
    </div>
  </div>
  <table
    mat-table
    [dataSource]="orders"
    matSort
    matSortActive="receivedDate"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="ordersId">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by ordersId"
      >
        Orders Id
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          class="riva-button riva-button-sm riva-button-blue"
          [routerLink]="element.link"
          target="_blank"
        >
          {{ element.ordersId }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="customerName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by customerName"
      >
        Customer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.customerName }}</td>
    </ng-container>
    <ng-container matColumnDef="metal">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by metal"
      >
        Metal
      </th>
      <td mat-cell *matCellDef="let element">{{ element.metal }}</td>
    </ng-container>
    <ng-container matColumnDef="poInternal">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by poInternal"
      >
        RIVA PO
      </th>
      <td mat-cell *matCellDef="let element">{{ element.poInternal }}</td>
    </ng-container>
    <ng-container matColumnDef="poExternal">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by poExternal"
      >
        Customer PO
      </th>
      <td mat-cell *matCellDef="let element">{{ element.poExternal }}</td>
    </ng-container>
    <ng-container matColumnDef="receivedDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by receivedDate"
      >
        Received Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.receivedDate | date : 'MM/dd/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ordersType">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by ordersType"
      >
        Order Type
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ordersType }}</td>
    </ng-container>
    <ng-container matColumnDef="qtyOrdered">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        start="desc"
        sortActionDescription="Sort by qtyOrdered"
      >
        Ordered
      </th>
      <td mat-cell *matCellDef="let element">{{ element.qtyOrdered }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="9">No Available Data</td>
    </tr>
  </table>
</div>
