import { UserPermission } from 'src/app/core/user-permission/user-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
interface SubMenu {
  name: string;
  url: string;
  permissionName?: string;
  pageName?: string;
  permission?: UserPermission | null;
  isDefaultPage?: boolean;
  hidden?: boolean
}

interface Menu {
  name: string;
  url?: string;
  icon: string;
  subMenu?: SubMenu[];
  permissionName?: string;
  pageName?: string;
  permission?: UserPermission | null;
  isDefaultPage?: boolean
  hidden?: boolean
}

export const DEFAULT_ROUTE_PAGE = '/main/ordersview';

export const menus: Menu[] = [
  {
    name: 'Administration',
    icon: 'setting',
    subMenu: [
      {
        name: 'Accounts & Permissions',
        url: '/admin/accounts',
        permissionName: 'Accounts',
        pageName: PAGE_NAME.account,
      },
    ],
  },
  {
    name: 'Product & Materials',
    icon: 'shop',
    subMenu: [
      {
        name: 'Products',
        url: '/main/products',
        pageName: PAGE_NAME.product,
      },
      {
        name: 'Stones',
        url: '/main/stones',
        pageName: PAGE_NAME.stone,
      },
      {
        name: 'Findings',
        url: '/items/findings/jumprings',
        pageName: PAGE_NAME.finding,
      },
      {
        name: 'Chain',
        url: '/items/chain/chain-raw',
        pageName: PAGE_NAME.chain,
      },
      {
        name: 'Enamel',
        url: '/items/enamel',
        pageName: PAGE_NAME.enamel,
      },
      {
        name: 'Other Components',
        url: '/items/other-components',
        pageName: PAGE_NAME.otherComponents,
      },
    ],
  },
  {
    name: 'Control Office',
    icon: 'control',
    subMenu: [
      {
        name: 'Purchase Orders',
        url: '/main/ordersview',
        permissionName: 'Orders View',
        pageName: PAGE_NAME.order,
      },
      {
        name: 'Work Orders',
        url: '/reports/work-order',
        permissionName: 'Work Order',
        pageName: PAGE_NAME.workOrder,
      },
      {
        name: 'Open Order Report',
        url: '/main/open-order-report',
        permissionName: 'Open Order Report',
        pageName: PAGE_NAME.openOrderReport,
      },
      {
        name: 'Stone Open Order Report',
        url: '/main/stone-open-order-report',
        permissionName: 'Stone Open Order Report',
        pageName: PAGE_NAME.stoneOpenOrderReport,
      },
      {
        name: 'Order Details',
        url: '',
        permissionName: 'Order Details',
        pageName: PAGE_NAME.orderDetails,
        hidden: true,
      },
    ],
  },
  {
    name: 'Reporting',
    icon: 'line-chart',
    subMenu: [
      {
        name: 'Graphs',
        url: '/reporting/graph',
        pageName: PAGE_NAME.reporting,
      },
      {
        name: 'Item Weights',
        url: '/reporting/item-weights',
        pageName: PAGE_NAME.itemWeights,
      },
      {
        name: 'Work Order Movement',
        url: '/reporting/work-order-movement',
        pageName: PAGE_NAME.workOrderMovement,
      },
    ],
  },
  {
    name: 'Invoicing & Shipping',
    icon: 'credit-card',
    url: 'invoicing-and-shipping/invoicing',
    pageName: PAGE_NAME.invoicingAndShipping,
  },
  {
    name: 'Product Development',
    icon: 'rocket',
    subMenu: [
      {
        name: 'Suppliers',
        url: '/admin/suppliers',
        permissionName: 'Supplier/Customer',
        pageName: PAGE_NAME.supplier,
      },
      {
        name: 'Customers',
        url: '/admin/customers',
        permissionName: 'Supplier/Customer',
        pageName: PAGE_NAME.customer,
      },
    ],
  },
  {
    name: 'Product Query',
    icon: 'file-search',
    pageName: PAGE_NAME.productQuery,
    url: '/product-query',
  },
  {
    name: 'Work Order Scanning',
    icon: 'qrcode',
    subMenu: [
      {
        name: 'Work Order Tracking',
        pageName: PAGE_NAME.workOrderTracking,
        url: '/work-order-tracking',
      },
      {
        name: 'Package Label Printing',
        url: '/package-label-printing',
        pageName: PAGE_NAME.workOrderLabelPrinting,
      },
    ],
  },
];
