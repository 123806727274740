import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductInvoice } from './product-invoice';

@Component({
  selector: 'app-product-invoice',
  templateUrl: './product-invoice.component.html',
  styleUrls: ['./product-invoice.component.scss'],
})
export class ProductInvoiceComponent implements OnChanges, AfterViewInit {
  @Input() productInvoices: ProductInvoice[] = [];

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['invoicesId', 'customerName', 'invoicedDate', 'qty'];
  invoices = new MatTableDataSource<ProductInvoice>([]);
  totalQty = 0;

  ngAfterViewInit(): void {
    this.invoices.sort = this.sort;
  }

  ngOnChanges(): void {
    this.invoices.data = this.productInvoices ?? [];
    this.totalQty = this.invoices.data.reduce(
      (total, order) => total + order.qty,
      0,
    );
  }
}
