import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductInvoice } from '../components/product-query/product-invoice/product-invoice';
import { ProductOrder } from '../components/product-query/product-order/product-order';
import { GenericService } from './generic.service';

const getOrderType = (type: number) => {
  switch (type) {
    case 1:
      return 'Internal';
    case 2:
      return 'Online';
    case 3:
      return 'Repair';
    default:
      return 'Standard';
  }
};

@Injectable({
  providedIn: 'root',
})
export class SearchProductService extends GenericService {
  baseUrl = environment.apiUrl + 'search-product';

  constructor(http: HttpClient) {
    super(http);
  }
  getOrders({
    itemId,
    itemType,
    from,
    to,
  }: {
    itemId: number;
    itemType: number;
    from: string;
    to: string;
  }): Observable<ProductOrder[]> {
    return this.http
      .get<ProductOrder[]>(`${this.baseUrl}/orders`, {
        headers: this.headers,
        params: {
          itemId,
          itemType,
          from,
          to,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            ordersType: getOrderType(item.ordersTypeId),
            link: `/main/orders/${item.ordersId}`,
          })),
        ),
      );
  }

  getOrdersDetails({
    itemId,
    itemType,
    from,
    to,
  }: {
    itemId: number;
    itemType: number;
    from: string;
    to: string;
  }): Observable<ProductOrder[]> {
    return this.http
      .get<ProductOrder[]>(`${this.baseUrl}/orders/details`, {
        headers: this.headers,
        params: {
          itemId,
          itemType,
          from,
          to,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            ordersType: getOrderType(item.ordersTypeId),
            link: `/main/orders/${item.ordersId}`,
          })),
        ),
      );
  }

  getInvoices({
    itemId,
    itemType,
    from,
    to,
  }: {
    itemId: number;
    itemType: number;
    from: string;
    to: string;
  }): Observable<ProductInvoice[]> {
    return this.http
      .get<ProductInvoice[]>(`${this.baseUrl}/invoices`, {
        headers: this.headers,
        params: {
          itemId,
          itemType,
          from,
          to,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            link: `/invoicing-and-shipping/invoicing/${item.invoicesId}`,
          })),
        ),
      );
  }

  getInvoicesDetails({
    itemId,
    itemType,
    from,
    to,
  }: {
    itemId: number;
    itemType: number;
    from: string;
    to: string;
  }): Observable<ProductInvoice[]> {
    return this.http
      .get<ProductInvoice[]>(`${this.baseUrl}/invoices/details`, {
        headers: this.headers,
        params: {
          itemId,
          itemType,
          from,
          to,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            link: `/invoicing-and-shipping/invoicing/${item.invoicesId}`,
          })),
        ),
      );
  }
}
