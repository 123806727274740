import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import {
  RivaGems,
  StoneClarity,
  StoneColor,
  StoneCut,
  StoneType,
} from '../riva-gems.model';
import { StoneService } from '../stone.service';

@Component({
  selector: 'gems-list',
  templateUrl: './gems-list.component.html',
  styleUrls: ['./gems-list.component.scss'],
})
export class GemsListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedGem: RivaGems;
  @Input() list: RivaGems[];
  @Input() editMode: boolean;
  @Output() onSelectGem = new EventEmitter();
  @Output() onSetEditMode = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  gems = new MatTableDataSource<RivaGems>([]);
  displayedColumns = [
    'stonesID',
    'pic',
    'type',
    'cut',
    'clarity',
    'color',
    'caratsPerUnit',
    'sizeD1',
    'pricePerUnit',
    'qtyInSTock',
    'labGrown',
    'traceable',
  ];
  search = '';
  stoneTypes: StoneType[] = [
    {
      stoneTypesID: 0,
      name: 'All',
    },
  ];
  stoneCuts: StoneCut[] = [
    {
      stoneCutsID: 0,
      name: 'All',
      custom: false,
    },
  ];
  stoneClarities: StoneClarity[] = [
    {
      stoneClaritiesID: 0,
      name: 'All',
    },
  ];
  stoneColors: StoneColor[] = [
    {
      stoneColorsID: 0,
      colorName: 'All',
    },
  ];
  filter = {
    stoneTypesID: 0,
    stoneCutsID: 0,
    stoneClaritiesID: 0,
    stoneColorsID: 0,
    labGrown: -1,
    traceable: -1,
    hideOutOfStock: false,
  };
  searchText = '';
  isExportingFile = false;

  constructor(private stoneService: StoneService) {}

  ngOnInit(): void {
    forkJoin([
      this.stoneService.getTypes(),
      this.stoneService.getCuts(),
      this.stoneService.getClarities(),
      this.stoneService.getColors(),
    ]).subscribe(([types, cuts, clarities, colors]) => {
      this.stoneTypes = [...this.stoneTypes, ...types];
      this.stoneCuts = [...this.stoneCuts, ...cuts];
      this.stoneClarities = [...this.stoneClarities, ...clarities];
      this.stoneColors = [...this.stoneColors, ...colors];
    });
    this.stoneService.reSelectStone = (id) => {
      const [stone] = this.gems.data ?? [];
      const selected =
        id > 0 ? this.gems.data.find((s) => s.stonesID === id) : stone ?? {};
      this.onSelectGem.emit({ ...selected });
    };
  }

  ngOnChanges(): void {
    this.gems.data = this.list;
  }
  ngAfterViewInit() {
    this.gems.sort = this.sort;
    this.gems.paginator = this.paginator;
    this.gems.filterPredicate = (data: RivaGems, filterValue: string) => {
      const {
        stoneTypesID,
        stoneCutsID,
        stoneClaritiesID,
        stoneColorsID,
        labGrown,
        traceable,
        hideOutOfStock,
      } = JSON.parse(filterValue);
      const typeFilter =
        stoneTypesID === 0 || data.stoneTypesID === stoneTypesID;
      const cutFilter = stoneCutsID === 0 || data.stoneCutsID === stoneCutsID;
      const clarityFilter =
        stoneClaritiesID === 0 || data.stoneClaritiesID === stoneClaritiesID;
      const colorFilter =
        stoneColorsID === 0 || data.stoneColorsID === stoneColorsID;
      const labGrownFilter =
        labGrown === -1 || data.labGrown === (labGrown > 0 ? true : false);
      const traceableFilter =
        traceable === -1 || data.traceable === (traceable > 0 ? true : false);
      const sizeSearch =
        !this.searchText ||
        (data.size ?? data.stoneSize.displayText)
          .toString()
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      const inStock =
        !hideOutOfStock || (hideOutOfStock && (data.qtyInSTock ?? 0) > 0);

      return (
        typeFilter &&
        cutFilter &&
        clarityFilter &&
        colorFilter &&
        labGrownFilter &&
        traceableFilter &&
        sizeSearch &&
        inStock
      );
    };
    this.gems.sortingDataAccessor = (data: any, sortHeaderId: string): any => {
      if (sortHeaderId === 'size') {
        return (
          (data.stoneSize?.dimension_1 ?? 0) * 1000 +
          (data.stoneSize?.dimension_2 ?? 0) * 100 +
          (data.stoneSize?.dimension_3 ?? 0) * 1
        );
      } else if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId]?.toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
  }

  onFilter() {
    const {
      stoneTypesID,
      stoneCutsID,
      stoneClaritiesID,
      stoneColorsID,
      labGrown,
      traceable,
      hideOutOfStock,
    } = this.filter;
    this.gems.filter = JSON.stringify({
      stoneTypesID,
      stoneCutsID,
      stoneClaritiesID,
      stoneColorsID,
      labGrown,
      traceable,
      hideOutOfStock,
    });
  }

  onTableRowClick(gem) {
    if (this.editMode) return;
    this.onSelectGem.emit({ ...gem });
  }

  onDownloadExcelFile() {
    this.isExportingFile = true;
    this.stoneService
      .getStoneExcel()
      .subscribe((response) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'riva-stone-list.xlsx'
        a.click();
        window.URL.revokeObjectURL(url);
        this.isExportingFile = false;
      });
  }
}
