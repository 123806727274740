import { Enamel } from 'src/app/models/enamel';
import { RivaGems } from '../components/riva-gems/riva-gems.model';

export class WorkOrder {
  workOrdersId: number = 0;
  createdDate: Date;
  closedDate: Date;
  location: string = '';
  workOrderDetails: WorkOrderDetail[] = [];
}

export class WorkOrderDetail {
  workOrderDetailsId: number = 0;
  workOrdersId: number = 0;
  ordersDetailsId: number = 0;
  qtyinit: number = 1;
  qtyclosed: number = 0;
  wgtpitem: number = 0;
}

export class WorkOrderReport {
  workOrdersId: number = 0;
  createdDate: Date;
  closedDate: Date;
  location: string = '';
  workOrderPrintReports: WorkOrderPrintReport[] = [];
}

export class WorkOrderPrintReport {
  workOrderId: number = 0;
  workOrderDetailsId: number = 0;
  qtyinit: number = 0;
  qtyclosed: number = 0;
  itemName: string = '';
  itemPhoto: string = '';
  routingJSON: string = '';
  bomjson: string = '';
  metal: string = '';
  size: string = '';
  guid: string = '';
}

export class WorkOrderDetailDisplay {
  productName: string = '';
  photo: string = '';
  material: string = '';
  size: string = '';
  workOrderDetail: WorkOrderDetail = new WorkOrderDetail();
}

export interface WorkOrderUI {
  orderId: number;
  poInternal: string;
  poExternal: string;
  ordersId: number;
  dueDate: Date;
  productId: number;
  productName: string;
  sku: string;
  picturePath: string;
  workOrdersId: number;
  workOrderId?: number;
  createdDate: Date;
  workOrderDetailsId: number;
  quantity: number;
  quantityClosed: number | null;
  printedDate: Date | null;
  releasedDate: Date | null;
  dateClosed: Date | null;
  isAlmostDue?: boolean;
  isAlreadyDue?: boolean;
  materialCode?: string;
  qtyTotal?: number;
  printColor1?: string;
  customized?: boolean;
  productSku?: string;
  materialDescription?: string;
  isFullyClosed?: boolean;
  isPartiallyClosed?: boolean;
  orderType?: string;
  primaryMaterial: MultiMaterial | null;
  secondaryMaterial: MultiMaterial | null;
  customerName: string;
  customerId?: number;
}
interface MultiMaterial {
  materialCode: string;
  materialDescription: string;
  color: string;
  printColor1: string;
}
interface ProductPrint {
  productId: number;
  productName: string;
  sku: string;
  description: string;
  customerCode: number;
  uom: number;
  picture: string;
}
interface MaterialPrint {
  materialCodeId: number;
  code: string;
  karat: string;
  color: string;
  description: string;
  printColor1: string;
  printColor2: string;
}
interface OrderDetailPrint {
  orderDetailsId: number;
  qtyOrdered: number;
  entryDate: string;
  dueDate: string;
  comment: string;
}

interface PurchaseOrderPrint {
  orderId: number;
  poInternal: string;
  poExternal: string;
  receivedDate: string;
  entryDate: string;
  customerId: number;
  comment: string;
  requiredDate: string;
}
interface WorkOrderPrint {
  workOrdersId: number;
  createdDate: string;
  closedDate: string;
}

export interface WorkOrderDetailPrint {
  workOrderDetailsId: number;
  qty: number;
  qtyClosed: string | null;
  dateClosed: string | null;
  orderDetails: {
    orderDetailsId: number;
    qtyOrdered: number;
    entryDate: string;
    dueDate: string;
    comment: string;
    size: string;
    enamel: Enamel;
    cipo: string;
    stone: RivaGems;
  };
}

export interface WorkOrderDetailsForPrint {
  customized?: boolean;
  product: ProductPrint;
  material: MaterialPrint;
  orderDetail: OrderDetailPrint;
  purchaseOrder: PurchaseOrderPrint;
  workOrder: WorkOrderPrint;
  workOrderDetails: WorkOrderDetailPrint[];
  quantity: number;
}

export interface ClosingDetail {
  index: string;
  workOrdersClosingID: number;
  workOrdersDetailsId: number;
  qtyClosed: number;
  dateClosed: string;
  weightGrams: number;
}
export interface ClosingDetailApi {
  index: string;
  details: Array<Omit<ClosingDetail, 'index'>>;
}

export interface WorkOrderDates {
  createdDate: string;
  printedDate: string;
  releasedDate: string;
  stoneReleaseDate: string;
  closedDate: string;
  dueDate: string;
}

export interface WorkOrderDetailForPDF {
  index: string;
  qty: number;
  workOrdersDetailsId: number;
  ordersDetailsAltId: number;
  ordersDetailsId: number;
}
