<div style="padding: 0 20px">
  <div class="form-row">
    <div class="row mt-3" style="width: 700px">
      <div class="col">
        <span style="color: white"
          >LAST UPDATE BY : {{ productLastHistory.lastUpdatedBy }} &#47; DATE :
          {{
            productLastHistory.lastUpdated | date : 'MM/dd/yyyy hh:mma'
          }}</span
        >
      </div>
    </div>

    <div class="row search-panel" style="margin-bottom: 25px; margin-top: 10px">
      <div class="col-md-9 col-sm-8 col-xs-8" style="padding: 0">
        <div class="row">
          <div class="col-3">
            <mat-form-field
              class="search-input"
              appearance="fill"
              style="width: 100%"
            >
              <mat-label>Search</mat-label>
              <input
                matInput
                [(ngModel)]="searchString"
                (ngModelChange)="getFilteredProducts()"
                [disabled]="editMode"
              />
            </mat-form-field>
          </div>
          <div class="col-2">
            <!-- <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Customer</mat-label>
              <input
                type="text"
                matInput
                [(ngModel)]="selectedCustomerObj"
                [formControl]="customerFilterCtrl"
                [matAutocomplete]="auto"
                (ngModelChange)="onCustomerChange(selectedCustomerObj)"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="customerDisplay"
              >
                <mat-option
                  class="riva-select-header riva-select__stick-header"
                >
                  <div class="riva-select">
                    <div class="p5 riva-select__customer-id">ID</div>
                    <div class="p5 riva-select__name">Name</div>
                  </div>
                </mat-option>
                <mat-option class="riva-select-header">
                  <div class="riva-select">
                    <div class="p5 riva-select__customer-id">ID</div>
                    <div class="p5 riva-select__name">Name</div>
                  </div>
                </mat-option>
                <mat-option
                  [value]="allCustomer"
                  style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
                >
                  <div class="country-container riva-select">
                    <div class="p5 riva-select__customer-id">All</div>
                    <div class="p5 riva-select__name"></div>
                  </div>
                </mat-option>
                <mat-option
                  *ngFor="let customer of filteredCustomers"
                  [value]="customer"
                  style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
                >
                  <div class="country-container riva-select">
                    <div class="p5 riva-select__customer-id">
                      {{ customer.customerId }}
                    </div>
                    <div class="p5 riva-select__name">
                      {{ customer.companyName }}
                    </div>
                  </div>
                </mat-option>
                <mat-option *ngIf="!filteredCustomers.length">
                  <div class="text-center riva-select__not-found">
                    Not Found
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Customer</mat-label>
              <mat-select
                placeholder="Customer"
                [(ngModel)]="selectedCustomer"
                (ngModelChange)="searchPanelChange()"
                [disabled]="editMode"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    [hideClearSearchButton]="true"
                    [formControl]="customerFilterCtrl"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0"> All </mat-option>
                <mat-option
                  *ngFor="let customer of filteredCustomers"
                  [value]="customer.custIdno"
                >
                  [{{ customer.customerId }}] {{ customer.companyName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Jewelry types</mat-label>
              <mat-select
                placeholder="Customer"
                [(ngModel)]="selectedJewelryType"
                (ngModelChange)="searchPanelChange()"
                [disabled]="editMode"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    [hideClearSearchButton]="true"
                    [formControl]="jewelryTypeFilterCtrl"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0"> All </mat-option>
                <mat-option
                  *ngFor="let jewelryType of filteredJewelryTypes"
                  [value]="jewelryType.jewelryTypeId"
                >
                  {{ jewelryType.type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-1" style="color: white; line-height: 50px">
            Items: {{ filteredProducts?.length ?? 0 }}
          </div>
          <div class="col-4" style="display: flex">
            <button
              class="btn btn-success"
              [disabled]="!product.productsId"
              style="height: 45px; margin-right: 8px"
              (click)="onOpenGenerateSkuDialog()"
            >
              SKUs
            </button>
            <button
              class="riva-button"
              userPermission
              [elementKey]="productFeatureKey.newProductButton"
              [disabled]="editMode || !customers?.length"
              (click)="newProduct()"
              style="height: 45px; margin-right: 8px"
            >
              + New
            </button>
            <button
              class="riva-button"
              userPermission
              [elementKey]="productFeatureKey.productSetting"
              [disabled]="!product.productsId"
              (click)="onProductSettingDialogOpen()"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 110px;
                min-width: unset;
                height: 45px;
              "
            >
              <i
                nz-icon
                nzType="setting"
                nzTheme="outline"
                style="font-size: 18px"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-4">
        <div>
          <input
            type="file"
            #uploadImage
            (change)="uploadProductImage(uploadImage.files)"
            style="display: none"
            multiple
          />
          <button
            class="riva-button"
            (click)="uploadImage.click()"
            [disabled]="!product.productsId"
            style="display: flex; align-items: center; justify-content: center"
            *ngIf="editMode"
          >
            <i
              nz-icon
              nzType="file-image"
              nzTheme="outline"
              style="margin-right: 8px"
            ></i>
            Upload Pictures
          </button>
        </div>
      </div>
    </div>

    <div class="table-row row">
      <div
        id="productTable"
        #productTable
        class="table-container col-md-9 col-sm-8 col-xs-8"
        [ngClass]="{ 'table-collapsed': tableCollapsed }"
        (clickOutside)="tableActive = false"
        (click)="tableActive = true"
      >
        <div class="overlay"></div>
        <table class="table">
          <thead style="height: 40px !important">
            <tr class="col">
              <th scope="col" (click)="onSort('productsId')">
                <i class="fa" [ngClass]="sortClass('productsId')"></i>ID
              </th>
              <th scope="col" (click)="onSort('sku')">
                <i class="fa" [ngClass]="sortClass('sku')"></i> Short Name
              </th>
              <th scope="col" (click)="onSort('productName')">
                <i class="fa" [ngClass]="sortClass('productName')"></i> Full
                Name
              </th>
              <th scope="col" (click)="onSort('customerCode')">
                <i class="fa" [ngClass]="sortClass('customerCode')"></i> Cust
                Code
              </th>
              <th scope="col" (click)="onSort('jewelryTypeName')">
                <i class="fa" [ngClass]="sortClass('jewelryTypeName')"></i>
                Jewelry Type
              </th>
            </tr>
          </thead>
          <tbody *ngIf="tableCollapsed === false && !editMode">
            <tr
              *ngFor="let item of filteredProducts"
              class="row-data"
              [ngClass]="{
                'row-data-active': item.productsId === product.productsId
              }"
              (click)="getProductLocal(item)"
            >
              <td scope="row">{{ item.productsId }}</td>
              <td
                class="sku"
                scope="row"
                [innerHTML]="transform(item.sku)"
              ></td>
              <td scope="row">{{ item.productName }}</td>
              <td scope="row">{{ item.customerCodeNavigation.customerId }}</td>
              <td scope="row">{{ item.jewelryTypNavigation?.type }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="tableCollapsed === false && editMode">
            <tr
              *ngFor="let item of filteredProducts"
              class="row-data"
              [ngClass]="{
                'row-data-active': item.productsId === product.productsId
              }"
            >
              <td scope="row">{{ item.productsId }}</td>
              <td
                class="sku"
                scope="row"
                [innerHTML]="transform(item.sku)"
              ></td>
              <td scope="row">{{ item.productName }}</td>
              <td scope="row">{{ item.customerCodeNavigation.customerId }}</td>
              <td scope="row">{{ item.jewelryTypNavigation?.type }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="tableCollapsed">
            <tr class="row-data-active">
              <td scope="row">{{ product.productsId }}</td>
              <td
                class="sku"
                scope="row"
                [innerHTML]="transform(product.sku)"
              ></td>
              <td scope="row">{{ product.productName }}</td>
              <td scope="row">{{ product.jewelryTypeName }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-3 col-sm-4 col-xs-4">
        <app-product-image
          [editMode]="editMode && product.productsId"
          [productId]="product.productsId"
        ></app-product-image>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 form-input-container">
      <div class="product-form">
        <div class="form-row product-form__item">
          <div class="form-group">
            <input
              [(ngModel)]="product.productName"
              type="text"
              class="riva-input form-control"
              [ngClass]="{ 'is-invalid': error.name }"
              placeholder="FULL NAME"
              [disabled]="!editMode"
            />
            <div class="tool-tip">
              <div class="arrow-down"></div>
              Full Name
            </div>
          </div>
        </div>
        <div class="form-row product-form__item">
          <div class="form-group">
            <input
              [(ngModel)]="product.sku"
              type="text"
              class="riva-input form-control"
              [ngClass]="{ 'is-invalid': error.sku }"
              placeholder="SHORT NAME"
              [disabled]="!editMode"
            />
            <div class="tool-tip">
              <div class="arrow-down"></div>
              Short Name
            </div>
          </div>
        </div>
        <div class="product-form__column-4">
          <div class="form-row product-form__item">
            <div class="form-group">
              <select
                [(ngModel)]="product.customerCode"
                class="riva-input custom-select"
                [disabled]="!editMode"
              >
                <option
                  *ngFor="let customer of customers"
                  [value]="customer.custIdno"
                >
                  [{{ customer.customerId }}] {{ customer.companyName }}
                </option>
              </select>
              <div class="tool-tip">
                <div class="arrow-down"></div>
                CUST CODE
              </div>
            </div>
          </div>
          <div class="form-row product-form__item">
            <div class="form-group">
              <select
                [(ngModel)]="this.product.uom"
                class="riva-input custom-select"
                [(value)]="selectedUOM"
                [disabled]="!editMode"
              >
                <option
                  *ngFor="let unit of uom"
                  [value]="unit.unitsOfMeasureId"
                >
                  {{ unit.uom }}
                </option>
              </select>
              <div class="tool-tip">
                <div class="arrow-down"></div>
                UOM
              </div>
            </div>
          </div>
          <div class="form-row product-form__item">
            <div class="form-group">
              <select
                [(ngModel)]="this.product.jewelryType"
                class="riva-input custom-select"
                [disabled]="!editMode"
              >
                <option
                  *ngFor="let jewelryType of jewelryTypes"
                  [value]="jewelryType.jewelryTypeId"
                >
                  {{ jewelryType.type }}
                </option>
              </select>
              <div class="tool-tip">
                <div class="arrow-down"></div>
                Jewelry Type
              </div>
            </div>
          </div>
          <div class="form-row product-form__item">
            <div class="form-group">
              <select
                [(ngModel)]="product.status"
                class="riva-input custom-select"
                [disabled]="!editMode"
              >
                <option
                  *ngFor="let status of statuses"
                  [value]="status.statusId"
                >
                  {{ status.status }}
                </option>
              </select>
              <div class="tool-tip">
                <div class="arrow-down"></div>
                Status
              </div>
            </div>
          </div>
        </div>
        <div class="form-row product-form__item">
          <div class="form-group riva-textarea-container">
            <textarea
              class="riva-textarea"
              style="width: 100%"
              id="descbox"
              [(ngModel)]="product.productDesc"
              [disabled]="!editMode"
            ></textarea>
            <div class="tool-tip">
              <div class="arrow-down"></div>
              Description
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5 form-group-sm">
      <div class="form-row">
        <div class="col-md">
          <div class="form-group-boxed product-detail-panel">
            <div class="form-group row mat-size-container">
              <div class="col-sm-5">
                <label>Materials</label>
                <div class="mat-table">
                  <div
                    *ngFor="let item of productDetail.materials"
                    class="mat-row"
                    [ngClass]="{
                      'mat-size':
                        selectedMaterial.productsMaterialsID ==
                        item.productsMaterialsID,
                      'selected-mat-size': !highlightSize
                    }"
                    (click)="onSelectMaterialCode(item)"
                  >
                    <div
                      style="display: flex; align-items: center"
                      *ngIf="
                        !item.material?.multiMetalPrimaryMaterial ||
                        !item.material?.multiMetalSecondaryMaterial
                      "
                    >
                      <div
                        style="
                          width: 27px;
                          height: 20px;
                          border: 1px solid white;
                          background-color: rgb(0, 0, 0);
                          margin-right: 5px;
                        "
                        [ngStyle]="{
                          'background-color': '#' + item.material?.printColor1
                        }"
                      ></div>
                      {{ item.material?.description }}
                    </div>
                    <div
                      style="display: flex; align-items: center"
                      *ngIf="
                        item.material?.multiMetalPrimaryMaterial &&
                        item.material?.multiMetalSecondaryMaterial
                      "
                    >
                      <div class="multi-material">
                        <div
                          class="primary-material"
                          [ngStyle]="{
                            'border-color':
                              '#' +
                              item.material?.multiMetalPrimaryMaterial
                                ?.printColor1 +
                              ' transparent transparent transparent'
                          }"
                        ></div>
                        <div
                          class="secondary-material"
                          [ngStyle]="{
                            'border-color':
                              'transparent transparent #' +
                              item.material?.multiMetalSecondaryMaterial
                                ?.printColor1 +
                              ' transparent'
                          }"
                        ></div>
                      </div>
                      {{ item.material?.multiMetalPrimaryMaterial?.code }} /
                      {{ item.material?.multiMetalSecondaryMaterial?.code }}
                    </div>
                    <div style="justify-content: flex-end; display: flex">
                      <i
                        nz-icon
                        nzType="edit"
                        nzTheme="fill"
                        class="icon-edit"
                        *ngIf="editMode && item.material2"
                        userPermission
                        [elementKey]="productFeatureKey.deleteMaterial"
                        (click)="onOpenMultiMaterial(item)"
                      ></i>
                      <i
                        nz-icon
                        nzType="delete"
                        nzTheme="fill"
                        class="icon-delete"
                        *ngIf="editMode"
                        userPermission
                        [elementKey]="productFeatureKey.deleteMaterial"
                        (click)="
                          onDeleteProductMaterial(
                            item.productsMaterialsID,
                            item.material?.description
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="editMode" style="margin-top: 15px">
                  <mat-form-field appearance="fill" style="width: 100%">
                    <mat-label>Material Code</mat-label>
                    <mat-select
                      [(ngModel)]="productMaterialCode"
                      (ngModelChange)="onMaterialCodeChange()"
                    >
                      <mat-option
                        *ngFor="let mats of filteredMaterialCodes"
                        [value]="mats.materialCodeId"
                      >
                        {{ mats.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%"
                    *ngIf="metalGrainList?.length"
                  >
                    <mat-label>Metal Grain</mat-label>
                    <mat-select [(ngModel)]="productMetalGrain">
                      <mat-option
                        *ngFor="let metalGrain of metalGrainList"
                        [value]="metalGrain.metalGrainsId"
                      >
                        {{ metalGrain.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    class="riva-button riva-button-sm"
                    style="width: 100%"
                    [disabled]="!productMaterialCode || !product.productsId"
                    (click)="onSaveProductMaterial()"
                  >
                    Add Material
                  </button>
                  <button
                    class="riva-button riva-button-sm"
                    style="width: 100%; margin-top: 8px"
                    [disabled]="!product.productsId"
                    (click)="onOpenMultiMaterial()"
                  >
                    Add Multi Material
                  </button>
                </div>
              </div>
              <div class="col-sm-3" style="padding: 0">
                <label>Sizes</label>

                <div class="size-table">
                  <div *ngFor="let item of productDetail.productSizes">
                    <div
                      [ngClass]="{
                        'mat-size':
                          selectedProductSize.productsSizesID ==
                          item.productsSizesID
                      }"
                      (click)="onSelectSize(item)"
                      class="size-row"
                    >
                      <span>{{ item.size }}</span>
                      <i
                        nz-icon
                        nzType="delete"
                        nzTheme="fill"
                        class="icon-delete"
                        *ngIf="editMode"
                        userPermission
                        [elementKey]="productFeatureKey.deleteSize"
                        (click)="
                          onDeleteProductSize(item.productsSizesID, item.size)
                        "
                      ></i>
                    </div>
                  </div>
                </div>

                <div *ngIf="editMode" style="margin-top: 15px">
                  <div style="position: relative">
                    <mat-form-field style="width: 100%" appearance="fill">
                      <mat-label>Size</mat-label>
                      <input matInput [(ngModel)]="productSize" />
                    </mat-form-field>
                    <mat-error
                      *ngIf="showProductSizeError"
                      class="error-message"
                      >Size already exist.</mat-error
                    >
                  </div>
                  <button
                    class="riva-button riva-button-sm"
                    style="width: 100%"
                    [disabled]="!productSize || !product.productsId"
                    (click)="onSaveProductSize()"
                  >
                    Add Size
                  </button>
                  <button
                    class="riva-button riva-button-sm"
                    style="width: 100%; margin-top: 8px"
                    (click)="onProductSizesDialogOpen()"
                  >
                    Update Sizes
                  </button>
                </div>
              </div>
              <div class="col-sm-4">
                <div
                  class="size-material-container"
                  *ngIf="
                    selectedMaterial.materialsCodeID &&
                    selectedProductSize.productsSizesID
                  "
                >
                  <div class="material-container">
                    <div
                      *ngIf="
                        !selectedMaterial.material?.multiMetalPrimaryMaterial ||
                        !selectedMaterial.material?.multiMetalPrimaryMaterial
                      "
                      style="display: flex; align-items: center; gap: 1px"
                    >
                      <div
                        style="
                          width: 25px;
                          height: 18px;
                          border: 1px solid white;
                          background-color: rgb(0, 0, 0);
                          margin-right: 5px;
                        "
                        [ngStyle]="{
                          'background-color':
                            '#' + selectedMaterial?.material?.printColor1
                        }"
                      ></div>
                      <span>{{ selectedMaterial?.material?.code }}</span>
                    </div>
                    <div
                      style="display: flex; align-items: center"
                      *ngIf="
                        selectedMaterial.material?.multiMetalPrimaryMaterial &&
                        selectedMaterial.material?.multiMetalSecondaryMaterial
                      "
                    >
                      <div class="multi-material">
                        <div
                          class="primary-material"
                          [ngStyle]="{
                            'border-color':
                              '#' +
                              selectedMaterial.material
                                ?.multiMetalPrimaryMaterial?.printColor1 +
                              ' transparent transparent transparent'
                          }"
                        ></div>
                        <div
                          class="secondary-material"
                          [ngStyle]="{
                            'border-color':
                              'transparent transparent #' +
                              selectedMaterial.material
                                ?.multiMetalSecondaryMaterial?.printColor1 +
                              ' transparent'
                          }"
                        ></div>
                      </div>
                      {{
                        selectedMaterial.material?.multiMetalSecondaryMaterial
                          ?.code
                      }}
                      /
                      {{
                        selectedMaterial.material?.multiMetalSecondaryMaterial
                          ?.code
                      }}
                    </div>

                    <span style="margin: 0 10px">X</span>
                    <span>{{ selectedProductSize.size }}</span>
                  </div>
                  <mat-form-field appearance="fill" class="weight-field">
                    <mat-label>Weight (g)</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="selectedWeight.avgWeightGrams"
                      [disabled]="true"
                      class="weight-input"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <ng-template #materialCreate let-modal>
              <div class="product-detail-popup">
                <div class="modal-header row">
                  <h5 class="modal-title col-10" id="modal-basic-title">
                    Create Material and Sizes
                  </h5>
                  <button
                    type="button"
                    class="close col-2"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <div class="input-group">
                      <label>Material Code</label>

                      <select
                        [(ngModel)]="modalMaterialCode"
                        class="custom-select search-field"
                      >
                        <option
                          *ngFor="let mats of materialCodes"
                          [value]="mats.materialCodeId"
                        >
                          {{ mats.description }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label>SIZE</label>
                      <input
                        [(ngModel)]="modalSize"
                        type="text"
                        class="riva-input form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="riva-button"
                    (click)="modal.close('Save click')"
                  >
                    Save
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 form-group-sm">
      <div class="form-row mt-1" style="margin-bottom: 50px">
        <div class="col-md-6">
          <button
            *ngIf="!editMode"
            class="riva-button"
            (click)="toggleEdit()"
            userPermission
            [elementKey]="productFeatureKey.editProductButton"
            [disabled]="product.productsId === undefined"
          >
            EDIT
          </button>
          <button *ngIf="editMode" class="riva-button" (click)="toggleCancel()">
            CANCEL
          </button>
        </div>
        <div class="col-md-6">
          <button class="riva-button" [disabled]="!editMode" (click)="save()">
            SAVE
          </button>
        </div>
      </div>

      <div class="form-row mt-1">
        <div class="col-md-6">
          <button
            class="riva-button"
            [disabled]="!product.productsId"
            userPermission
            [elementKey]="productFeatureKey.viewProductRouting"
            (click)="onProductRoutingDialogOpen()"
          >
            Product Routing
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-bill-of-material
    [productId]="product.productsId"
    [editMode]="editMode && product.productsId"
  ></app-bill-of-material>
</div>

<ng-template #addProductRouting let-modal>
  <div class="product-routing-popup">
    <div class="modal-header row">
      <h5 class="modal-title col-10" id="modal-basic-title">Product Routing</h5>
      <button
        type="button"
        class="close col-2"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="grid-table">
        <div class="thead">
          <div class="tr">
            <div class="td">Activity Code</div>
            <div class="td">Description</div>
            <div class="td">Standard Time</div>
            <div class="td">Comment</div>
            <div class="td">
              <button
                class="btn btn-outline-success col-md-6"
                (click)="addnewrouting()"
              >
                <i class="fa fa-plus"></i> Add
              </button>
              <button
                class="btn btn-outline-success col-md-6"
                [disabled]="EnableUpdateRouting"
                (click)="updateProductRouting()"
              >
                <i class="fa fa-arrow-circle-up"></i> Update
              </button>
            </div>
            <div class="td">
              <span *ngIf="notification" class="elementToFadeInAndOut">
                <i class="fas fa-check-circle {{ notification.class }}"></i>
                {{ notification.message }}
              </span>
            </div>
          </div>
        </div>

        <div class="tbody">
          <form
            class="tr"
            *ngFor="let prodroute of routingForms.controls; let i = index"
            [formGroup]="prodroute"
          >
            <div class="td">
              <select class="form-control" formControlName="routingCodesId">
                <option
                  *ngFor="let item of routingCodes"
                  value="{{ item.routingCodesId }}"
                >
                  {{ item.activityCode }}
                </option>
              </select>
            </div>
            <div class="td">
              <span>{{
                getRoutingDescription(prodroute.value.routingCodesId)
              }}</span>
            </div>
            <div class="td stdtime">
              <input
                type="number"
                class="form-control"
                value="{{ prodroute.standardTime }}"
                formControlName="standardTime"
              />
            </div>

            <div class="td comment">
              <input
                class="form-control"
                value="{{ prodroute.comment }}"
                formControlName="comment"
              />
            </div>
            <div class="td">
              <button
                class="btn btn-outline-success col-3"
                (click)="
                  deleteRouting(prodroute.get('productsRoutingId').value, i)
                "
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
