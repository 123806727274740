<mat-dialog-content
  class="work-order-print work-order-print__container mat-typography"
>
  <div class="work-order-print__header">
    <div class="work-order-print__canvas-header">
      <div class="work-order-print__canvas-header-title">
        <button
          *ngIf="!isPrintedReport"
          [disabled]="isLoading"
          type="button"
          class="riva-button"
          (click)="print()"
        >
          <i class="fa fa-print"></i> Print WKO
        </button>
        <button
          *ngIf="isPrintedReport"
          (click)="onSetNotPrinted()"
          type="button"
          class="btn btn-confirm"
          style="margin: 0"
          userPermission
          [elementKey]="workOrderFeatureKey.notPrinted"
        >
          Mark as Unprinted
        </button>
        <button
          class="btn btn-danger"
          userPermission
          [elementKey]="workOrderFeatureKey.delete"
          (click)="onDeleteWorkOrder()"
        >
          Delete WKO
        </button>
      </div>
    </div>

    <div class="work-order-info">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Due Date</mat-label>
        <input matInput [(ngModel)]="selectedWorkOrder.dueDate" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Printed Date</mat-label>
        <input matInput [(ngModel)]="selectedWorkOrder.printedDate" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Released Date</mat-label>
        <input matInput [(ngModel)]="selectedWorkOrder.releasedDate" disabled />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Received Date</mat-label>
        <input matInput [(ngModel)]="selectedWorkOrder.receivedDate" disabled />
      </mat-form-field>
    </div>

    <button
      type="button"
      class="btn btn-confirm close-button"
      aria-label="Close"
      (click)="onCloseDialog()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
  </div>

  <div class="work-order-print__viewer">
    <div class="work-order-loader-container" *ngIf="isLoading">
      <div style="display: flex; align-items: center; flex-direction: column">
        <div class="work-order-loader">
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve"
          >
            <path
              fill="#68516d"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
        <h1 style="color: #68516d; margin-top: 15px">Generating Work Order</h1>
      </div>
    </div>
    <div
      class="work-order-print__sub-sku-info right"
      *ngIf="!isLoading && closingDetails.length > 0"
    >
      <div class="sub-sku-header">
        <div>Sub SKU & Quantities Details</div>
        <div userPermission [elementKey]="workOrderFeatureKey.weightEdit">
          <button
            class="btn btn-info btn-sm"
            [ngClass]="{ hidden: isEditWeight }"
            (click)="onSetEditMode()"
          >
            Edit
          </button>
          <button
            class="btn btn-danger btn-sm"
            [ngClass]="{ hidden: !isEditWeight }"
            (click)="onCancel()"
          >
            Cancel
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Index</th>
            <th style="width: 50px">Qty</th>
            <th style="min-width: 70px">Weight</th>
            <th>Closed Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of closingDetails">
            <td>{{ detail.index }}</td>
            <td>{{ detail.qtyClosed }}</td>
            <td>
              <nz-input-number
                [nzMin]="0.001"
                [nzMax]="99999.999"
                nzSize="small"
                [(ngModel)]="detail.weightGrams"
                [ngClass]="{ hidden: !isEditWeight }"
              ></nz-input-number>
              <span [ngClass]="{ hidden: isEditWeight }">
                {{ detail.weightGrams }}
              </span>
            </td>
            <td>
              {{ detail.dateClosed | date : 'MM/dd/yyyy' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex; justify-content: flex-end; margin-top: 16px">
        <button
          class="btn btn-success btn-sm"
          [ngClass]="{ hidden: !isEditWeight }"
          [disabled]="isSavingWeight"
          (click)="onSaveDetail()"
        >
          Save
        </button>
      </div>
    </div>

    <div class="work-order-print__sub-sku-info left" *ngIf="!isLoading">
      <div class="sub-sku-header">
        <div>Order Details</div>
      </div>
      <table style="width: 100%">
        <thead>
          <tr>
            <th>Index</th>
            <th style="width: 120px">Qty</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of workOrderDetails">
            <td>{{ detail.index }}</td>
            <td style="width: 120px">
              <nz-input-number
                [nzMin]="1"
                nzSize="small"
                [(ngModel)]="detail.qty"
                [ngClass]="{ hidden: !isEditDetails }"
              ></nz-input-number>
              <span [ngClass]="{ hidden: isEditDetails }">
                {{ detail.qty }}
              </span>
            </td>
            <td>
              <i
                nz-icon
                nzType="delete"
                nzTheme="fill"
                class="icon-delete"
                *ngIf="workOrderDetails.length > 1"
                (click)="onDeleteWorkOrderDetails(detail.workOrdersDetailsId)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex; justify-content: flex-end; margin-top: 16px">
        <button
          class="btn btn-success btn-sm"
          [ngClass]="{ hidden: !isEditDetails }"
          [disabled]="isSavingDetails"
          (click)="onSaveWorkOrderDetail()"
        >
          Save
        </button>
      </div>
    </div>
    <pdf-viewer
      *ngIf="!isLoading"
      [src]="pdfUrl"
      [render-text]="true"
      [original-size]="false"
      [show-all]="true"
      [zoom]="0.35"
      style="width: 100%; height: 100vh"
      (after-load-complete)="onLoaded($event)"
    ></pdf-viewer>
  </div>
</mat-dialog-content>
