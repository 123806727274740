import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchProductService } from 'src/app/services/search-product';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { ProductLookup } from '../reporting/work-order-movement/model';
import { ProductInvoice } from './product-invoice/product-invoice';
import { ProductOrder } from './product-order/product-order';

@Component({
  selector: 'app-product-query',
  templateUrl: './product-query.component.html',
  styleUrls: ['./product-query.component.scss'],
})
export class ProductQueryComponent implements OnInit {
  constructor(
    private workOrderMovementService: WorkOrderMovementService,
    private searchProductService: SearchProductService,
  ) {}

  productLookup: ProductLookup[] = [];
  filteredProductLookup: Observable<ProductLookup[]>;
  selectedProductLookup: ProductLookup = {} as ProductLookup;
  productLookupControl = new FormControl();

  productOrders: ProductOrder[] = [];
  productOrdersDetails: ProductOrder[] = [];
  productInvoices: ProductInvoice[] = [];
  productInvoicesDetails: ProductInvoice[] = [];

  filters = {
    from: DateTime.local().toFormat('yyyy-MM-dd'),
    to: DateTime.local().toFormat('yyyy-MM-dd'),
  };

  ngOnInit(): void {
    this.workOrderMovementService.getProductLookup().subscribe((products) => {
      this.productLookup = products;
      this.filteredProductLookup = this.productLookupControl.valueChanges.pipe(
        startWith(this.selectedProductLookup.productName),
        map((value) => this._filterProduct(value)),
      );
    });
  }

  onSelectProduct(product: string | ProductLookup) {
    if (typeof product === 'string' || (product?.productsId ?? 0) === 0) return;
    this.searchProductService
      .getOrders({
        itemId: product.productsId,
        itemType: product.itemType,
        from: this.filters.from,
        to: this.filters.to,
      })
      .subscribe((data) => {
        this.productOrders = data;
      });
    this.searchProductService
      .getOrdersDetails({
        itemId: product.productsId,
        itemType: product.itemType,
        from: this.filters.from,
        to: this.filters.to,
      })
      .subscribe((data) => {
        this.productOrdersDetails = data;
      });

    this.searchProductService
      .getInvoices({
        itemId: product.productsId,
        itemType: product.itemType,
        from: this.filters.from,
        to: this.filters.to,
      })
      .subscribe((data) => {
        this.productInvoices = data;
      });

    this.searchProductService
      .getInvoicesDetails({
        itemId: product.productsId,
        itemType: product.itemType,
        from: this.filters.from,
        to: this.filters.to,
      })
      .subscribe((data) => {
        this.productInvoicesDetails = data;
      });
  }

  onDateRangeChange({ start, end }) {
    this.filters.from = start;
    this.filters.to = end;
    this.onSelectProduct(this.selectedProductLookup);
  }

  displayValue(item: ProductLookup) {
    return item?.productName ?? '';
  }

  private _filterProduct(name: string): ProductLookup[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    }
    return this.productLookup;
  }
}
