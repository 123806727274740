<div class="product-query">
  <div class="product-query__filter">
    <mat-form-field appearance="fill" style="color: white">
      <mat-label>Search products</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="selectedProductLookup"
        (ngModelChange)="onSelectProduct($event)"
        [formControl]="productLookupControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayValue"
      >
        <mat-option
          *ngFor="let option of filteredProductLookup | async"
          [value]="option"
        >
          {{ option.productName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <riva-date-range-picker
      [startDate]="filters.from"
      [endDate]="filters.to"
      (onChange)="onDateRangeChange($event)"
    ></riva-date-range-picker>
  </div>
  <div class="tab-container">
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Product Order">
        <app-product-order [productOrders]="productOrders"></app-product-order>
      </nz-tab>
      <nz-tab nzTitle="Product Order Details">
        <app-product-order-details
          [productOrders]="productOrdersDetails"
        ></app-product-order-details>
      </nz-tab>
      <nz-tab nzTitle="Invoices">
        <app-product-invoice
          [productInvoices]="productInvoices"
        ></app-product-invoice>
      </nz-tab>
      <nz-tab nzTitle="Invoices Details">
        <app-product-invoice-details
          [productInvoices]="productInvoicesDetails"
        ></app-product-invoice-details>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
