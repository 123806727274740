import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountsTableComponent } from './components/accounts-table/accounts-table.component';
import { ChainTabComponent } from './components/chain-tab/chain-tab.component';
import { CustomerPageComponent } from './components/customer-page/customer-page.component';
import { EnamelComponent } from './components/enamel/enamel.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { InventoryLogComponent } from './components/inventory-log/inventory-log.component';
import { InvoicingShippingComponent } from './components/invoicing-shipping/invoicing-shipping.component';
import { InvoicingDetailComponent } from './components/invoicing-shipping/invoicing/invoicing-detail/invoicing-detail.component';
import { ShippingDetailComponent } from './components/invoicing-shipping/shipping/shipping-detail/shipping-detail.component';
import { LoginComponent } from './components/login/login.component';
import { ManufacturedMaterialsComponent } from './components/manufactured-materials/manufactured-materials.component';
import { MetalGrainComponent } from './components/metal-grain/metal-grain.component';
import { NonPreciousComponent } from './components/non-precious/non-precious.component';
import { OpenOrderReportComponent } from './components/open-order-report/open-order-report.component';
import { OpenOrderStoneReportComponent } from './components/open-order-stone-report/open-order-stone-report.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderviewComponent } from './components/orderview/orderview.component';
import { OtherComponentsComponent } from './components/other-components/other-components.component';
import { ProductQueryComponent } from './components/product-query/product-query.component';
import { ProductsTableComponent } from './components/products-table/products-table.component';
import { ItemWeightsComponent } from './components/reporting/item-weights/item-weights.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { WorkOrderMovementComponent } from './components/reporting/work-order-movement/work-order-movement.component';
import { RivaChainPageComponent } from './components/riva-chain/riva-chain-page/riva-chain-page.component';
import { RivaFindingsComponent } from './components/riva-findings/riva-findings.component';
import { RivaGemsComponent } from './components/riva-gems/riva-gems.component';
import { SupplierPageComponent } from './components/supplier-page/supplier-page.component';
import { WorkOrderLabelPrintingComponent } from './components/work-order-label-printing/work-order-label-printing.component';
import { WorkOrderTrackingComponent } from './components/work-order-tracking/work-order-tracking.component';
import { WorkOrderViewComponent } from './components/work-order-view/work-order-view.component';
import { AuthGuard } from './custom/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/main/ordersview' },
  { path: 'login', component: LoginComponent },
  {
    path: 'main',
    children: [
      {
        path: 'orders/:ordersId',
        component: OrdersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ordersview',
        component: OrderviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'open-order-report',
        component: OpenOrderReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'stone-open-order-report',
        component: OpenOrderStoneReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'products',
        component: ProductsTableComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'stones',
        component: RivaGemsComponent,
        canActivate: [AuthGuard],
      },
      { path: 'chain', component: ChainTabComponent, canActivate: [AuthGuard] },
    ],
  },
  {
    path: 'invoicing-and-shipping/:slug',
    component: InvoicingShippingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reporting',
    children: [
      {
        path: 'graph',
        component: ReportingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'item-weights',
        component: ItemWeightsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'work-order-movement',
        component: WorkOrderMovementComponent,
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: 'invoicing-and-shipping/invoicing/:invoiceId',
    component: InvoicingDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invoicing-and-shipping/shipping/:shippingId',
    component: ShippingDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'items',
    children: [
      {
        path: 'findings/:slug',
        component: RivaFindingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'chain/:slug',
        component: RivaChainPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'metal-grains',
        component: MetalGrainComponent,
        canActivate: [AuthGuard],
      },
      { path: 'enamel', component: EnamelComponent, canActivate: [AuthGuard] },
      {
        path: 'nonprecious',
        component: NonPreciousComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'manufactured-materials',
        component: ManufacturedMaterialsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'other-components',
        component: OtherComponentsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'admin',
    children: [
      {
        path: 'suppliers',
        component: SupplierPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'customers',
        component: CustomerPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'accounts',
        component: AccountsTableComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'reports',
    children: [
      {
        path: 'work-order',
        component: WorkOrderViewComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'inventory-log',
    component: InventoryLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'work-order-tracking',
    component: WorkOrderTrackingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'package-label-printing',
    component: WorkOrderLabelPrintingComponent,
    canActivate: [AuthGuard],
  },
  { path: '', component: ForbiddenComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  {
    path: 'product-query',
    component: ProductQueryComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/forbidden' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
