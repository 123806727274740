<h3 mat-dialog-title>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-circle-x-filled"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#ff4500"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path
      d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
      stroke-width="0"
      fill="#ff4500"
    />
  </svg>
  <span style="padding-left: 16px">Daily Metrics Report Error</span>
</h3>
<mat-dialog-content class="mat-typography">
  <div class="reporting-invoice-error">
    <nz-collapse>
      <nz-collapse-panel
        nzHeader="Invoices without metal weights"
        [nzActive]="true"
        *ngIf="invoices.length > 0"
      >
        <table mat-table [dataSource]="invoices">
          <ng-container matColumnDef="invoiceId">
            <th mat-header-cell *matHeaderCellDef>Invoice ID</th>
            <td mat-cell *matCellDef="let element">
              <a
                [routerLink]="element.link"
                target="blank"
                style="font-size: 14px; text-decoration: none; font-weight: 700"
              >
                {{ element.invoiceId }}
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: invoicesDisplayedColumns"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="1">No Invoice Error</td>
          </tr>
        </table>
      </nz-collapse-panel>
      <nz-collapse-panel
        nzHeader="Stones with missing PPU"
        [nzActive]="true"
        *ngIf="stones.length > 0"
      >
        <table mat-table [dataSource]="stones">
          <ng-container matColumnDef="stoneId">
            <th mat-header-cell *matHeaderCellDef>Stone ID</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneType">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneCut">
            <th mat-header-cell *matHeaderCellDef>Cut</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneCut }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneClarity">
            <th mat-header-cell *matHeaderCellDef>Clarity</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneClarity }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneColor">
            <th mat-header-cell *matHeaderCellDef>Color</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneColor }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stoneSize">
            <th mat-header-cell *matHeaderCellDef>Size</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stoneSize }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="stoneDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: stoneDisplayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="6">No Stone Error</td>
          </tr>
        </table>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      padding: 16px;
    "
  >
    <button
      class="btn btn-primary"
      style="width: 150px; height: 40px"
      mat-dialog-close
      autofocus
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
