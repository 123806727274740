import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadService } from './../../..//custom/load-overlay/load-overlay.service';
import {
  GenericComponent,
  Guid,
} from './../../../components/generic/generic.component';
import { AlertService } from './../../../custom/_alert';

import { ProductService } from 'src/app/services/product.service';
import { OrderdetailsService } from './../orderdetails.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { ORDER_DETAILS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/order-details-permission';
import { WORK_ORDER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/work-order-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Customers } from 'src/app/models/customer';
import { ProductFindings } from 'src/app/models/findings.model';
import { MaterialCode } from 'src/app/models/material-code';
import {
  OrderDetailsType,
  Orders,
  OrdersDetails,
} from 'src/app/models/orders.model';
import {
  Product,
  ProductEnamel,
  ProductVariation,
  ProductsInfo,
  ProductsRouting,
  WorkOrderEnamel,
} from 'src/app/models/product';
import { ProductSku } from 'src/app/models/sku';
import { SubItem } from 'src/app/models/sub-item.model';
import {
  WorkOrderDetailsForPrint,
  WorkOrderPrintReport,
} from 'src/app/models/work-order';
import { ChainService } from 'src/app/services/chain.service';
import { EnamelService } from 'src/app/services/enamel.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';
import { getSizeLabel } from 'src/app/services/other-components.service';
import {
  ProductBomService,
  sizeMapper,
} from 'src/app/services/product-bom.service';
import { ProductsRoutingService } from 'src/app/services/products-routing.service';
import { SubItemService } from 'src/app/services/sub-item.service';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { OrderDetailAlt } from '../../riva-chain/riva-chain-finish/models';
import { ChainRaw } from '../../riva-chain/riva-chain/models';
import { FINDINGS_TYPE } from '../../riva-findings/constants';
import {
  ProductStone,
  StoneSizeOrderDetails,
} from '../../riva-gems/riva-gems.model';
import { WorkOrderPrintDialogComponent } from '../../work-order-view/work-order-print/work-order-print-dialog.component';
import { SharedService } from './../../../services/shared.service';
import { AddProductByCustomerSkuDialogComponent } from './add-product-by-customer-sku-dialog/add-product-by-customer-sku-dialog.component';

export const getOrderDetailsSizeDisplayText = ({
  dimension1,
  dimension2,
  dimension3,
}: StoneSizeOrderDetails) => {
  const dimensions = [];
  if (dimension1) dimensions.push(`${dimension1}mm`);
  if (dimension2) dimensions.push(`${dimension2}mm`);
  if (dimension3) dimensions.push(`${dimension3}mm`);
  return dimensions.join(' x ');
};

const getOrderDetailAltProductName = (detail: OrderDetailAlt) => {
  if ((detail.otherComponents?.otherComponentsID ?? 0) > 0) {
    return detail.otherComponents.longName;
  } else if (detail.findings?.findingsId > 0) {
    const findingsType = detail.findingsTypes.findingsName
      ? `${detail.findingsTypes.findingsName} | `
      : '';
    return `${findingsType}${
      detail.findings.name || sizeMapper(detail.findingsSizes)
    }`;
  } else if (detail.chain_FinishedID > 0) {
    return `Finished${
      detail.chainFinished.name ? ' | ' + detail.chainFinished.name : ''
    }${
      detail.chainFinished?.chainRaw?.sku
        ? ' | ' + detail.chainFinished?.chainRaw?.sku?.trim()
        : ''
    }`;
  } else {
    return `Unfinished${
      detail.chainRaw?.chainStyle?.styleName
        ? ' | ' + detail.chainRaw?.chainStyle?.styleName
        : ''
    }${detail.chainRaw.sku ? ' | ' + detail.chainRaw.sku?.trim() : ''}`;
  }
};

const getOrderDetailAltSize = (detail: OrderDetailAlt) => {
  if ((detail.otherComponentsSizes?.otherComponentsSizesID ?? 0) > 0) {
    return getSizeLabel(detail.otherComponentsSizes);
  } else if (detail.findings?.findingsId > 0) {
    return sizeMapper(detail.findingsSizes);
  } else if (detail.chain_FinishedID > 0) {
    return detail.chainFinishedSizes.size;
  } else {
    return detail.chainRawLength ? `${detail.chainRawLength}"` : '';
  }
};

const getOrderDetailProductId = (detail: OrderDetailAlt) => {
  if ((detail.otherComponents?.otherComponentsID ?? 0) > 0) {
    return detail.otherComponents.otherComponentsID;
  } else if (detail.findings?.findingsId > 0) {
    return detail.findings.findingsId;
  } else if (detail.chain_FinishedID > 0) {
    return detail.chain_FinishedID;
  } else {
    return detail.chain_RawID;
  }
};

const getOrderDetailProductSizeId = (detail: OrderDetailAlt) => {
  if ((detail.otherComponentsSizes?.otherComponentsSizesID ?? 0) > 0) {
    return detail.otherComponentsSizes.otherComponentsSizesID;
  } else if (detail.findings?.findingsId > 0) {
    return detail.findingsSizes.findingSizesID;
  } else if (detail.chain_FinishedID > 0) {
    return detail.chain_FinishedSizesID;
  } else {
    return detail.chain_RawSizesID;
  }
};
const findingsTabSlugByIndex = {
  0: 'jumprings',
  1: 'posts',
  2: 'flyers',
  3: 'springs',
  4: 'spring-rings',
  5: 'lobster-claws',
  6: 'tags',
};
const getFindingsSlug = (typeId) => {
  switch (typeId) {
    case FINDINGS_TYPE.JUMPRINGS:
      return 'jumprings';
    case FINDINGS_TYPE.POST:
      return 'posts';
    case FINDINGS_TYPE.FLYER:
      return 'flyers';
    case FINDINGS_TYPE.SPRING:
      return 'springs';
    case FINDINGS_TYPE.SPRING_RING:
      return 'spring-rings';
    case FINDINGS_TYPE.LOBSTER_CLAW:
      return 'lobster-claws';
    case FINDINGS_TYPE.TAGS:
      return 'tags';
    default:
      return 'jumprings';
  }
};

@Component({
  selector: 'orderdetails-list',
  templateUrl: './list.component.html',
  styleUrls: [
    './list.component.scss',
    '../../work-order-view/work-order-view.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class ListComponent
  extends GenericComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatTable) table: MatTable<OrdersDetails>;
  @ViewChild('newOrderDetailsTable') newTable: MatTable<OrdersDetails>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() _ordersId: number;
  @Input() _customerId: number;
  @Input() dueDate: Date;
  @Input() ordersType: number;
  @Input() productList: Product[] = [];
  @Input() customersList: Customers[] = [];
  @Input() materialCodes: MaterialCode[] = [];
  @Output() onLoad = new EventEmitter<{
    hasItems: boolean;
    hasUnInvoiced: boolean;
  }>();

  workOrderFeatureKey = WORK_ORDER_FEATURE_KEY;
  orderDetailsFeatureKey = ORDER_DETAILS_FEATURE_KEY;
  products: Product[] = [];
  // productLookups: Product[] = [];
  orderDetails: OrdersDetails[] = [];
  allProductsInfo: ProductsInfo[] = [];
  order: Orders = {} as Orders;
  modalActive: boolean = false;
  isAddNewOrderDetail = false;
  isAddNewChainOrderDetail = false;
  isAddNewOtherComponentOrderDetail = false;
  orderDetail: OrdersDetails;
  karatItems = [...Array(6).keys()];
  enamelVariations: ProductVariation[] = [];
  workOrderForPrint: WorkOrderDetailsForPrint = {} as WorkOrderDetailsForPrint;
  enamels: WorkOrderEnamel[] = [];
  productFindings: ProductFindings[] = [];
  selectedWorkOrder: {
    dueDate: string;
    printedDate: string;
    releasedDate: string;
    receivedDate: string;
  };

  dataSource: OrdersDetails[];
  orderDetailsData = new MatTableDataSource<OrdersDetails>([]);
  columnProductInfo = ['productName', 'materialCode', 'size'];
  columnOthers = [
    'qtyordered',
    'backOrdered',
    'qtyshipped',
    'qtyinvoiced',
    'shipDate',
    'entryDate',
    'dueDate',
    'comment',
    'printAction',
  ];
  columnsToDisplay = [...this.columnProductInfo, ...this.columnOthers];

  expandedElement: OrdersDetails | null;
  selectedDetailId: number;
  subItems: SubItem[] = [];
  productRoutings: ProductsRouting[] = [];
  productEnamels: ProductEnamel[] = [];
  isPrintedReport = false;
  workOrderDueDate: Date | null;
  productStonesConstants: ProductStone[] = [];
  productStonesVariations: ProductStone[] = [];
  chainOrderDetail: Partial<OrdersDetails> = {} as OrdersDetails;
  isAddNewFindingsOrderDetail = false;
  findingsOrderDetail: Partial<OrdersDetails> = {} as OrdersDetails;
  otherComponentOrderDetail: Partial<OrdersDetails> = {} as OrdersDetails;
  isOrderOnline = false;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    private router: Router,
    private enamelService: EnamelService,
    private productBomService: ProductBomService,
    private workOrderService: WorkOrderService,
    private orderService: OrderService,
    private orderdetailsService: OrderdetailsService,
    private productService: ProductService,
    private subItemService: SubItemService,
    private productsRoutingService: ProductsRoutingService,
    // private materialCodeService: MaterialCodeService,
    private userPermissionService: UserPermissionService,
    private chainService: ChainService,
    private modalService: NgbModal,
    private invoiceService: InvoiceService,
    private _confirmationService: ConfirmationService,
  ) {
    super(loadService, alertService);
  }

  ngOnInit(): void {
    this.load(this._customerId, this._ordersId);
    this.isOrderOnline = this.ordersType === 2;
    this.loadService.reloadOrderDetails = () => {
      this.load(this._customerId, this._ordersId);
    };
    this.sharedService.byPassWorkOrder = () => {
      this._confirmationService
        .showConfirmation({
          title: 'Bypass All Order Items?',
          content: 'Click continue to bypass all order item',
          confirmLabel: 'Continue',
        })
        .subscribe((isConfirmed) => {
          if (!isConfirmed) return;
          const data = this.orderDetails.reduce((items, o) => {
            if (!o.workOrder?.workOrderId && !o.bypassWKOs) {
              return [
                ...items,
                {
                  id: o.ordersDetailsId,
                  byPass: true,
                  type: o.orderDetailsType ?? 0,
                },
              ];
            }
            return items;
          }, []);

          this.invoiceService.byPassWorkOrder(data).subscribe(() => {
            this.load(this._customerId, this._ordersId);
          });
        });
    };
  }

  ngAfterViewInit() {
    this.orderDetailsData.sort = this.sort;
  }

  onOpenDetail(detail: OrdersDetails) {
    detail.isOpened = true;
    this.selectedDetailId =
      this.selectedDetailId === detail.ordersDetailsId
        ? null
        : detail.ordersDetailsId;
  }

  checkValidColumns() {
    const hasEnamel = this.orderDetails.some(
      (detail) => detail.orderDetailsEnamel?.enamelID,
    );
    const hasStone = this.orderDetails.some(
      (detail) => detail.stone?.ordersDetailsStoneId,
    );
    const hasCiPo = this.orderDetails.some((detail) => detail.cipo?.length);
    const hasCustomizedItem = this.orderDetails.some(
      (detail) => detail.customized,
    );
    const columnInfo = [...this.columnProductInfo];
    hasEnamel && columnInfo.push('enamel');
    hasStone && columnInfo.push('stoneText');
    hasCiPo && columnInfo.push('cipo');
    hasCustomizedItem && columnInfo.push('customized');
    this.columnsToDisplay = [...columnInfo, ...this.columnOthers];
    if (this.ordersType === 1 || this.ordersType === 3) {
      const cols = ['backOrdered', 'qtyshipped', 'qtyinvoiced'];
      this.columnsToDisplay = this.columnsToDisplay.filter(
        (c) => !cols.includes(c),
      );
    }
  }

  getChainRawSize(detail: ChainRaw) {
    const rawSize = [];
    if (detail.linkDim1) rawSize.push(`${detail.linkDim1}mm`);
    if (detail.linkDim2) rawSize.push(`${detail.linkDim2}mm`);
    if (detail.wireSize) rawSize.push(`${detail.wireSize}mm`);
    return rawSize.join(' x ');
  }

  load(customerId, ordersId) {
    forkJoin([
      this.orderdetailsService.getByOrderId(ordersId),
      this.chainService.getChainOrderDetail(ordersId),
    ])
      .pipe(
        map(([orderdetailsList, chainOrderDetails]) => {
          this.products = this.productList.filter(
            (p) => p.customerCode == customerId || p.customerCode == 5,
          );
          const chainOrders = chainOrderDetails.map((c) => ({
            ordersDetailsId: c.orderDetailsAltID,
            ordersId: c.ordersID,
            productsId: getOrderDetailProductId(c),
            chainType: c.chain_FinishedID > 0,
            productsInfoId: 0,
            qtyordered: c.qtyOrdered,
            qtyshipped: c.qtyshipped,
            qtyinvoiced: c.qtyinvoiced,
            backOrdered: c.backOrdered,
            entryDate: c.entryDate,
            dueDate: c.dueDate,
            custAdrsId: 0,
            comment: c.comment,
            materialCodeID: c.materialCodeID,
            productSizesID: getOrderDetailProductSizeId(c),
            size: getOrderDetailAltSize(c),
            materialCode: c.material?.code,
            cipo: c.cipo,
            customized: c.customized,
            productName: getOrderDetailAltProductName(c),
            workOrder: c.workOrder,
            chainRawLength: c.chain_FinishedID > 0 ? 0 : c.chainRawLength,
            shipDate: c.shipDate,
            bypassWKOs: c.bypassWKOs,
            isFindings: (c.findings?.findingsId ?? 0) > 0,
            isOtherComponent: (c.otherComponents?.otherComponentsID ?? 0) > 0,
            findingsID: c.findings?.findingsId ?? 0,
            findingsSizesID: c.findingsSizes?.findingsSizesID ?? 0,
            findingsTypesID: c.findingsTypes?.findingsTypesID ?? 0,
            orderDetailsType: OrderDetailsType.OrderDetailsAlt,
          }));

          this.orderDetails = [...orderdetailsList, ...chainOrders];
          const hasUnInvoiced = this.orderDetails.some(
            (o) => o.qtyordered > o.qtyinvoiced,
          );
          this.onLoad.emit({
            hasItems: this.orderDetails.length > 0,
            hasUnInvoiced,
          });

          this.checkValidColumns();
          this.sharedService.showGenerateWorkOrderAndByPass =
            this.orderDetails.some(
              (o) => !o.workOrder?.workOrderId && !o.bypassWKOs,
            );
          this.orderDetailsData.data = this.orderDetails.map((o) => {
            const materialCode = this.materialCodes.find(
              (m) => m.materialCodeId === o.materialCodeID,
            );
            return {
              ...o,
              materialColor: `#${materialCode?.printColor1}`,
              primaryMaterialColor: materialCode.multiMetal
                ? `#${materialCode.multiMetalPrimaryMaterial?.printColor1}`
                : null,
              secondaryMaterialColor: materialCode.multiMetal
                ? `#${materialCode.multiMetalSecondaryMaterial?.printColor1}`
                : null,
              workOrder: {
                ...o.workOrder,
                dueDate: o.workOrder?.dueDate ?? o.dueDate.toString(),
              },
            };
          });
          return { orderdetailsList };
        }),
      )
      .subscribe();
  }

  workOrderPrintReport: WorkOrderPrintReport = new WorkOrderPrintReport();

  selectPrintReport(reportItem) {
    this.workOrderPrintReport = reportItem;
    this.workOrderPrintReport.guid = Guid.newGuid();
    this.orderService
      .getByOrderDetailsId(reportItem.workOrderDetailsId)
      .subscribe((result) => {
        this.order = result;
      });
  }

  onSetPrintedDate() {
    if (!this.workOrderForPrint.workOrder.workOrdersId) return;
    this.workOrderService
      .setWorkOrderPrint(this.workOrderForPrint.workOrder.workOrdersId)
      .subscribe(() => {
        this.orderdetailsService
          .getByOrderId(this._ordersId)
          .subscribe((data) => {
            this.orderDetails = data;
            this.orderDetailsData.data = data;
          });
      });
  }
  onSetNotPrinted() {
    if (!this.workOrderForPrint.workOrder.workOrdersId) return;
    this.workOrderService
      .setWorkOrderNotPrint(this.workOrderForPrint.workOrder.workOrdersId)
      .subscribe(() => {
        this.isPrintedReport = false;
        this.selectedWorkOrder.printedDate = '';
        this.orderdetailsService
          .getByOrderId(this._ordersId)
          .subscribe((data) => {
            this.orderDetails = data;
            this.orderDetailsData.data = this.orderDetails;
          });
      });
  }

  routingList(routing: string) {
    let list = [];
    if (!this.checkStringIfEmpty(routing)) {
      list = JSON.parse(routing);
    }
    return list;
  }

  bomList(bom: string) {
    let list = [];
    if (!this.checkStringIfEmpty(bom)) {
      list = JSON.parse(bom);
    }
    return list;
  }

  modalOpen(content) {
    this.modalActive = true;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  getProductName(id) {
    let productname = '';
    let product = this.productList.filter((x) => x.productsId == id)[0];
    if (product != undefined) {
      productname = product.productName;
    }

    return productname;
  }

  getProductInfoDescByID(productsInfoId) {
    let materialsize = '';
    let productInfo = this.allProductsInfo.filter(
      (x) => x.productsInfoId == productsInfoId,
    )[0];
    if (productInfo != undefined) {
      materialsize = this.getProductInfoDesc(
        productInfo.materialCodeId,
        productInfo.size,
      );
    }

    return materialsize;
  }

  getProductInfoDesc(materialCodeId: number, size: string) {
    let returnStr = '';

    if (materialCodeId == undefined) {
      return returnStr;
    }

    let materialCode = this.materialCodes.filter(
      (x) => x.materialCodeId == materialCodeId,
    )[0];

    returnStr = materialCode.code + '  |  SZ-' + size;

    return returnStr;
  }

  newExpandedElement: OrdersDetails | null;
  newOrderDetails: OrdersDetails[] = [];

  newOrderDetail() {
    let newOrderDetail = new OrdersDetails();
    newOrderDetail.ordersId = this.orderDetails[0].ordersId;
    newOrderDetail.custAdrsId = this.orderDetails[0].custAdrsId;
    // newOrderDetail.productsId = this.orderDetails[0].productsId;
    this.newOrderDetails.push(newOrderDetail);
    this.newTable.renderRows();
  }

  onPrint($event, details) {
    $event.stopPropagation();
    this.workOrderService
      .getWorkOrderPrintDetail(details.workOrdersID)
      .subscribe((data: { workOrderPrintReports: WorkOrderPrintReport[] }) => {
        const [reportItem] = data.workOrderPrintReports;
        this.selectPrintReport(reportItem);
      });
  }
  return(value) {
    return value;
  }

  getWKOValue(value) {
    let x = '0000000';
    let x_split = x.split('');
    let value_split = (value + '').split('');
    let x_length = x_split.length;
    let value_length = value_split.length;
    let starting_index = x_length - value_length;
    let index = 0;
    for (starting_index; starting_index < x_length; starting_index++) {
      if (index < value_length) {
        x_split[starting_index] = value_split[index];
        index++;
      }
    }

    return x_split.join('');
  }

  onAddNewOrderDetail() {
    this.orderDetail = new OrdersDetails();
    const [orderItem] = this.orderDetails;
    this.orderDetail.ordersId = orderItem?.ordersId ?? this._ordersId;
    this.orderDetail.custAdrsId = orderItem?.custAdrsId ?? 0;
    this.orderDetail.dueDate = this.dueDate ?? new Date();
    this.setNewOrderDetailFlag();
  }

  onAddNewChainOrderDetail() {
    this.chainOrderDetail = new OrdersDetails();
    const [orderItem] = this.orderDetails;
    this.chainOrderDetail.ordersId = orderItem?.ordersId ?? this._ordersId;
    this.chainOrderDetail.custAdrsId = orderItem?.custAdrsId ?? 0;
    this.chainOrderDetail.dueDate = this.dueDate ?? new Date();
    this.setNewChainOrderDetailFlag();
  }
  onAddNewFindingsOrderDetail() {
    this.findingsOrderDetail = new OrdersDetails();
    const [orderItem] = this.orderDetails;
    this.findingsOrderDetail.ordersId = orderItem?.ordersId ?? this._ordersId;
    this.findingsOrderDetail.custAdrsId = orderItem?.custAdrsId ?? 0;
    this.findingsOrderDetail.dueDate = this.dueDate ?? new Date();
    this.setNewFindingsOrderDetailFlag();
  }

  onAddNewOtherComponentOrderDetail() {
    this.otherComponentOrderDetail = new OrdersDetails();
    const [orderItem] = this.orderDetails;
    this.otherComponentOrderDetail.ordersId =
      orderItem?.ordersId ?? this._ordersId;
    this.otherComponentOrderDetail.custAdrsId = orderItem?.custAdrsId ?? 0;
    this.otherComponentOrderDetail.dueDate = this.dueDate ?? new Date();
    this.setNewOtherComponentOrderDetailFlag();
  }

  onCancelOrderDetail() {
    this.orderDetail = new OrdersDetails();
    this.setNewOrderDetailFlag();
  }
  onCancelChainOrderDetail() {
    this.chainOrderDetail = new OrdersDetails();
    this.setNewChainOrderDetailFlag();
  }

  onOrderDetailSuccess() {
    this.setNewOrderDetailFlag();
    this.loadService.reloadOrderDetails();
  }

  onChainOrderDetailSuccess() {
    this.setNewChainOrderDetailFlag();
    this.loadService.reloadOrderDetails();
  }

  onChainOrderDetailUpdateSuccess() {
    this.loadService.reloadOrderDetails();
  }

  onFindingsOrderDetailSuccess() {
    this.setNewFindingsOrderDetailFlag();
    this.loadService.reloadOrderDetails();
  }
  onFindingsOrderDetailUpdateSuccess() {
    this.loadService.reloadOrderDetails();
  }
  onCancelFindingsOrderDetail() {
    this.findingsOrderDetail = new OrdersDetails();
    this.setNewFindingsOrderDetailFlag();
  }
  onOtherComponentOrderDetailSuccess() {
    this.setNewOtherComponentOrderDetailFlag();
    this.loadService.reloadOrderDetails();
  }
  onOtherComponentOrderDetailUpdateSuccess() {
    this.selectedDetailId = null;
    this.loadService.reloadOrderDetails();
  }
  onCancelOtherComponentOrderDetail() {
    this.otherComponentOrderDetail = new OrdersDetails();
    this.setNewOtherComponentOrderDetailFlag();
  }

  setNewOrderDetailFlag() {
    this.isAddNewOrderDetail = !this.isAddNewOrderDetail;
  }
  setNewChainOrderDetailFlag() {
    this.isAddNewChainOrderDetail = !this.isAddNewChainOrderDetail;
  }
  setNewFindingsOrderDetailFlag() {
    this.isAddNewFindingsOrderDetail = !this.isAddNewFindingsOrderDetail;
  }
  setNewOtherComponentOrderDetailFlag() {
    this.isAddNewOtherComponentOrderDetail =
      !this.isAddNewOtherComponentOrderDetail;
  }

  onPrintSelectedWorkOrder($event, workOrder, content) {
    $event.stopPropagation();
    const { workOrderId, printedDate } = workOrder;
    this.selectedWorkOrder = {
      dueDate: workOrder.dueDate
        ? DateTime.fromJSDate(new Date(workOrder.dueDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      printedDate: workOrder.printedDate
        ? DateTime.fromJSDate(new Date(workOrder.printedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      releasedDate: workOrder.releasedDate
        ? DateTime.fromJSDate(new Date(workOrder.releasedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      receivedDate: workOrder.closedDate
        ? DateTime.fromJSDate(new Date(workOrder.closedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
    };
    this.isPrintedReport = !!printedDate;
    this.modalOpen(content);
    this.workOrderService
      .getWorkOrderForPrint(workOrderId)
      .subscribe((data) => {
        this.workOrderForPrint = data;
        this.getSubItems(data.product.productId);

        if (this.workOrderForPrint.workOrderDetails.length > 1) {
          const workOrdersForDueDate =
            this.workOrderForPrint.workOrderDetails.map((w) => ({
              ...w,
              dueDate: DateTime.fromJSDate(
                new Date(w.orderDetails.dueDate),
              ).toJSDate(),
            }));
          const [workOrderItem] = orderBy(workOrdersForDueDate, ['dueDate']);
          this.workOrderDueDate = workOrderItem.dueDate;
        } else {
          const [workOrderItem] = this.workOrderForPrint.workOrderDetails;
          this.workOrderDueDate = workOrderItem?.orderDetails.dueDate
            ? new Date(workOrderItem?.orderDetails.dueDate)
            : null;
        }

        this.productStonesVariations =
          this.workOrderForPrint.workOrderDetails.reduce(
            (variations, detail) => {
              if (!detail.orderDetails.stone.ordersDetailsStonesID)
                return variations;
              const stone = {
                ...detail.orderDetails.stone,
                size: getOrderDetailsSizeDisplayText(
                  detail.orderDetails.stone.size as StoneSizeOrderDetails,
                ),
              };
              return [...variations, stone];
            },
            [],
          );

        this.getProductRouting(data.product.productId);
        this.getProductEnamels(data.product.productId);
        this.getProductFindings(data.product.productId);
        this.getProductPictures(data.product.productId);
        this.getProductStones(data.product.productId);
      });
  }

  getProductStones(productId) {
    this.productBomService
      .getProductStones(productId)
      .subscribe((productStones) => {
        this.productStonesConstants = productStones.filter((s) => !s.variation);
      });
  }

  getSubItems(productId) {
    this.subItemService.getSubItems(productId).subscribe((data) => {
      const availableSize = this.workOrderForPrint.workOrderDetails.map(
        (detail) => detail.orderDetails.size,
      );
      this.subItems = data?.map((item) => ({
        ...item,
        subItemsInfo: item.subItemsInfo
          ?.filter((s) => availableSize.includes(s.size))
          ?.map((subItemInfo) => ({
            ...subItemInfo,
            mfgMethodDetails:
              subItemInfo?.mfgMethod === 3 ? '' : subItemInfo?.mfgMethodDetails,
            stlPath:
              subItemInfo?.mfgMethod === 3
                ? encodeURI(subItemInfo?.mfgMethodDetails)
                : '',
          })),
      }));
    });
  }
  getProductFindings(productId) {
    this.productBomService.getProductFindings(productId).subscribe((data) => {
      this.productFindings = data;
    });
  }
  getEnamelVariations(productId) {
    this.productService.getProductVariations(productId).subscribe((data) => {
      this.enamelVariations = data.filter((variation) =>
        this.workOrderForPrint.workOrderDetails.some(
          (workOrder) =>
            workOrder.orderDetails?.enamel?.enamelSku === variation.enamelSKU,
        ),
      );
    });
  }

  getProductEnamels(productId) {
    forkJoin([
      this.enamelService.getList(),
      this.productBomService.getProductEnamel(productId),
    ])
      .pipe(
        map(([enamels, enamelList]) => {
          const enamelVariations = enamelList
            .filter((e) => e.variation)
            .map((v) => {
              const enamel =
                enamels.find((e) => e.enamelId === v.enamelID) ?? {};
              return { ...enamel, ...v };
            });
          const productEnamels = enamelList
            .filter((e) => !e.variation)
            .map((p) => {
              const enamel =
                enamels.find((e) => e.enamelId === p.enamelID) ?? {};
              return { ...enamel, ...p };
            });
          return { productEnamels, enamelVariations };
        }),
      )
      .subscribe(({ productEnamels, enamelVariations }) => {
        this.productEnamels = productEnamels.filter((enamel) =>
          this.workOrderForPrint.workOrderDetails.some(
            (workOrder) =>
              workOrder.orderDetails?.enamel?.enamelSku === enamel.enamelSku,
          ),
        );

        const variations = enamelVariations.filter((enamel) =>
          this.workOrderForPrint.workOrderDetails.some(
            (workOrder) =>
              workOrder.orderDetails?.enamel?.enamelSku === enamel.enamelSku,
          ),
        );

        this.enamels = uniqBy(
          [...productEnamels, ...variations].map(
            ({ enamelName, colorHex, enamelSKU, enamelSku }) => ({
              enamelName,
              colorHex,
              enamelSKU: enamelSKU ?? enamelSku,
            }),
          ),
          'enamelSKU',
        );
      });
  }

  getProductPictures(productId) {
    this.productService
      .getProductImageList(productId)
      .subscribe((data = []) => {
        this.workOrderForPrint.product.picture = data[0]?.fileName;
      });
  }

  getProductRouting(productId) {
    this.productsRoutingService
      .getProductRoutings(productId)
      .subscribe((data) => {
        this.productRoutings = data;
      });
  }

  getFontSize(text = '') {
    let fontSize = 45;
    if (!text) return `${fontSize}px`;
    switch (true) {
      case text.length <= 25:
        fontSize = 45;
        break;
      case text.length <= 45:
        const size = text.length - 25;
        fontSize = 45 - size;
        break;
      case text.length <= 65:
        fontSize = Math.abs((text.length - 45) * 0.35 - 25);
        break;
      default:
        fontSize = 14;
        break;
    }
    return `${fontSize}px`;
  }

  hasAccess(key) {
    return this.userPermissionService.hasAccess(key);
  }

  onDeleteDetail($event, detail: OrdersDetails) {
    $event.stopPropagation();
    this._confirmationService
      .showConfirmation({
        title: 'Delete Order Detail',
        content: 'Are you sure you want to delete this order detail?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.orderdetailsService
          .deleteOrder(detail.ordersDetailsId, detail.orderDetailsType)
          .subscribe();
        this.orderDetails = this.orderDetails.filter(
          (o) => o.ordersDetailsId !== detail.ordersDetailsId,
        );
        this.orderDetailsData.data = this.orderDetails;
      });
  }
  onByPassOrder($event, detail: OrdersDetails) {
    $event.stopPropagation();
    this._confirmationService
      .showConfirmation({
        title: 'Bypass this order?',
        content: 'Click continue to bypass this order',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.invoiceService
          .byPassWorkOrder([
            {
              id: detail.ordersDetailsId,
              byPass: true,
              type: detail.orderDetailsType ?? 0,
            },
          ])
          .subscribe(() => {
            detail.bypassWKOs = true;
            const allOrderClosed = this.orderDetails.every(
              (o) =>
                o.workOrder?.workOrderId ||
                o.bypassWKOs ||
                o.ordersDetailsId === detail.ordersDetailsId,
            );
            if (allOrderClosed) {
              this.sharedService.showGenerateWorkOrderAndByPass = false;
            }
          });
      });
  }
  onUndoByPassOrder($event, detail: OrdersDetails) {
    $event.stopPropagation();
    this._confirmationService
      .showConfirmation({
        title: 'Remove the bypass for this order?',
        content: 'Click continue to remove the bypass for this order',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;

        this.orderdetailsService
          .bypassWorkOrder({
            type: detail.orderDetailsType ?? 0,
            id: detail.ordersDetailsId,
            byPass: false,
          })
          .subscribe(() => {
            detail.bypassWKOs = false;
            this.sharedService.showGenerateWorkOrderAndByPass = true;
          });
      });
  }

  onPrintWorkOrder($event, workOrder) {
    $event.stopPropagation();

    const ref = this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: { ...workOrder, dateClosed: workOrder.closedDate },
      },
    });
    ref.afterClosed().subscribe((isReload) => {
      if (isReload) {
        this.load(this._customerId, this._ordersId);
      }
    })
  }

  onRedirectToProduct($event, product: OrdersDetails) {
    $event.stopPropagation();
    if (product.chainType == null && !product.isFindings) {
      localStorage.setItem(
        'selected-product-id',
        product.productsId?.toString(),
      );
      this.router.navigate(['/main/products']);
    } else if (product.chainType != null && !product.isFindings) {
      if (product.chainType) {
        localStorage.setItem(
          'selected-chain-finished-id',
          product.productsId?.toString(),
        );
      } else {
        localStorage.setItem(
          'selected-chain-raw-id',
          product.productsId?.toString(),
        );
      }
      const type = product.chainType ? 'chain-finished' : 'chain-raw';
      this.router.navigate([`/items/chain/${type}`]);
    } else if (product.isFindings) {
      const type = getFindingsSlug(product.findingsTypesID);
      localStorage.setItem(
        `selected-findings-id-${type}`,
        product.productsId?.toString(),
      );
      this.router.navigate([`/items/findings/${type}`]);
    }
  }

  onAddProductByCustomerSku() {
    const dialogRef = this.dialog.open(AddProductByCustomerSkuDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      autoFocus: false,
      data: {
        customerId: this._customerId,
      },
    });
    dialogRef.afterClosed().subscribe((data: ProductSku) => {
      if (!data) return;

      this.orderDetail = new OrdersDetails();
      const [orderItem] = this.orderDetails;
      this.orderDetail.ordersId = orderItem?.ordersId ?? this._ordersId;
      this.orderDetail.custAdrsId = orderItem?.custAdrsId ?? 0;
      this.orderDetail.dueDate = this.dueDate ?? new Date();
      this.orderDetail.productsId = data.productsID;
      this.orderDetail.materialCodeID = data.productsMaterialsID;
      this.orderDetail.productSizesID = data.productsSizesID;
      this.orderDetail.enamelId = data.productsBOMEnamelID;
      this.orderDetail.stoneId = data.productsBOMStonesID;
      this.orderDetail.rawChainId = data.productsBOMChainRawID;
      this.orderDetail.finishChainId = data.productsBOMChainFinishedID;
      this.orderDetail.isInitialData = true;

      this.setNewOrderDetailFlag();
    });
  }
}
