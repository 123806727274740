<div class="product-sizes-dialog">
  <h3 mat-dialog-title>Product Sizes</h3>
  <mat-dialog-content class="mat-typography product-sizes-dialog__container">
    <div class="table-container">
      <table mat-table matSort [dataSource]="data.productSizes">
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">
            {{ element.size }}
          </td>
        </ng-container>
        <ng-container matColumnDef="newSize">
          <th mat-header-cell *matHeaderCellDef>New Size Name</th>
          <td mat-cell *matCellDef="let element">
            <input nz-input [(ngModel)]="element.newSize" />
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="1">No Available Data</td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 10px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSave()"
      [disabled]="isSaving || !isFormValid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
