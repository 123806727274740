import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductOrder } from './product-order';

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss'],
})
export class ProductOrderComponent implements OnChanges, AfterViewInit {
  @Input() productOrders: ProductOrder[] = [];

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'ordersId',
    'customerName',
    'poInternal',
    'poExternal',
    'receivedDate',
    'ordersType',
    'qtyOrdered',
  ];
  orders = new MatTableDataSource<ProductOrder>([]);
  totalOrders = 0;

  ngAfterViewInit(): void {
    this.orders.sort = this.sort;
  }

  ngOnChanges(): void {
    this.orders.data = this.productOrders ?? [];
    this.totalOrders = this.orders.data.reduce(
      (total, order) => total + order.qtyOrdered,
      0,
    );
  }
}
